/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: update_info.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as update_info_pb from './update_info_pb'; // proto import: "update_info.proto"


export class UpdateInfoServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListUpdateInfo = new grpcWeb.MethodDescriptor(
    '/proto.UpdateInfoService/ListUpdateInfo',
    grpcWeb.MethodType.UNARY,
    update_info_pb.ListUpdateInfoRequest,
    update_info_pb.ListUpdateInfoResponse,
    (request: update_info_pb.ListUpdateInfoRequest) => {
      return request.serializeBinary();
    },
    update_info_pb.ListUpdateInfoResponse.deserializeBinary
  );

  listUpdateInfo(
    request: update_info_pb.ListUpdateInfoRequest,
    metadata?: grpcWeb.Metadata | null): Promise<update_info_pb.ListUpdateInfoResponse>;

  listUpdateInfo(
    request: update_info_pb.ListUpdateInfoRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: update_info_pb.ListUpdateInfoResponse) => void): grpcWeb.ClientReadableStream<update_info_pb.ListUpdateInfoResponse>;

  listUpdateInfo(
    request: update_info_pb.ListUpdateInfoRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: update_info_pb.ListUpdateInfoResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.UpdateInfoService/ListUpdateInfo',
        request,
        metadata || {},
        this.methodDescriptorListUpdateInfo,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.UpdateInfoService/ListUpdateInfo',
    request,
    metadata || {},
    this.methodDescriptorListUpdateInfo);
  }

}

