import { GetterTree, MutationTree, ActionTree } from 'vuex'
import {PointApi} from "@/components/modules/intersection/services/api/PointApi";

const pointApi = new PointApi()

class State {
    pointsByProject: any
    isPointsByProject: boolean
    earnedPointsChart: any
    isEarnedPointsChart: boolean
    cityActions: any
    earnedPointsTable: any
    isEarnedPointsTable: boolean
    earnedPointsGroupTable: any
    isEarnedPointsGroupTable: boolean
    exportEarnedPointsTable: any
    isExportEarnedPointsTable: boolean
    minMaxDate: any
    isMinMaxDate: any
}

const getters = <GetterTree<State, any>> {
    getPointsByProject(state) {
        return state.pointsByProject
    },

    isPointsByProject(state) {
        return state.isPointsByProject
    },

    getEarnedPointsChart(state) {
        return state.earnedPointsChart
    },

    isEarnedPointsChart(state) {
        return state.isEarnedPointsChart
    },

    getCityActions(state) {
        return state.cityActions
    },

    getEarnedPointsTable(state) {
        return state.earnedPointsTable
    },

    isEarnedPointsTable(state) {
        return state.isEarnedPointsTable
    },

    getEarnedPointsGroupTable(state) {
        return state.earnedPointsGroupTable
    },

    isEarnedPointsGroupTable(state) {
        return state.isEarnedPointsGroupTable
    },

    getExportEarnedPointsTable(state) {
        return state.exportEarnedPointsTable
    },

    isExportEarnedPointsTable(state) {
        return state.isExportEarnedPointsTable
    },

    getMinMaxPointDate(state) {
        return state.minMaxDate
    },

    isMinMaxPointDate(state) {
        return state.isMinMaxDate
    },
}

const mutations = <MutationTree<State>> {
    setPointsByProject(state, payload) {
        state.pointsByProject = payload
    },

    setIsPointsByProject(state, payload) {
        state.isPointsByProject = payload
    },

    setEarnedPointsChart(state, payload) {
        state.earnedPointsChart = payload
    },

    setIsEarnedPointsChart(state, payload) {
        state.isEarnedPointsChart = payload
    },

    setCityActions(state, payload) {
        state.cityActions = payload
    },

    setEarnedPointsTable(state, payload) {
        state.earnedPointsTable = payload
    },

    setIsEarnedPointsTable(state, payload) {
        state.isEarnedPointsTable = payload
    },

    setEarnedPointsGroupTable(state, payload) {
        state.earnedPointsGroupTable = payload
    },

    setIsEarnedPointsGroupTable(state, payload) {
        state.isEarnedPointsGroupTable = payload
    },

    setExportEarnedPointsTable(state, payload) {
        state.exportEarnedPointsTable = payload
    },

    setIsExportEarnedPointsTable(state, payload) {
        state.isExportEarnedPointsTable = payload
    },

    setMinMaxPointDate(state, payload) {
        state.minMaxDate = payload
    },

    setIsMinMaxPointDate(state, payload) {
        state.isMinMaxDate = payload
    },
}

const actions = <ActionTree<State, any>> {
    getPointsByProject({ commit }, data) {
        commit('setIsPointsByProject', true)

        return new Promise((resolve, reject) => {
            pointApi
                .getPointsByProject(data)
                .then(res => {
                    const items = res.getItemsList()

                    commit('setPointsByProject', items)
                    commit('setIsPointsByProject', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setPointsByProject', [])
                    commit('setIsPointsByProject', false)

                    reject(err)
                })
        })
    },

    getEarnedPointsChart({ commit }, data) {
        commit('setIsEarnedPointsChart', true)

        return new Promise((resolve, reject) => {
            pointApi
                .getCityEarnedChart(data)
                .then(res => {
                    const items = res.getItemsList()

                    commit('setEarnedPointsChart', items)
                    commit('setIsEarnedPointsChart', false)

                    resolve(items)
                })
                .catch(err => {
                    commit('setEarnedPointsChart', [])
                    commit('setIsEarnedPointsChart', false)

                    reject(err)
                })
        })
    },

    getCityActions({ commit }, data) {
        return new Promise((resolve, reject) => {
            pointApi
                .getCityActions(data)
                .then(res => {
                    const items = res.getItemsList()

                    commit('setCityActions', items)

                    resolve(items)
                })
                .catch(err => {
                    commit('setCityActions', [])

                    reject(err)
                })
        })
    },

    getEarnedPointsTable({ commit }, data) {
        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        commit('setIsEarnedPointsTable', true)

        return new Promise((resolve, reject) => {
            pointApi
                .getPointsTable(filter, sort, pagination, data)
                .then(res => {
                    const response = {
                        items: res.getItemsList(),
                        pagination: res.getParams()?.getPagination()?.getCnt()
                    }

                    commit('setEarnedPointsTable', response)
                    commit('setIsEarnedPointsTable', false)

                    resolve(response)
                })
                .catch(err => {
                    commit('setEarnedPointsTable', [])
                    commit('setIsEarnedPointsTable', false)

                    reject(err)
                })
        })
    },

    getEarnedPointsGroupTable({ commit }, data) {
        const filter: any =
            data !== undefined && data.filter !== undefined ? data.filter : []
        const sort: any =
            data !== undefined && data.sort !== undefined ? data.sort : {}
        const pagination: any =
            data !== undefined && data.pagination !== undefined ? data.pagination : {}

        commit('setIsEarnedPointsGroupTable', true)
        commit('setEarnedPointsGroupTable', [])

        return new Promise((resolve, reject) => {
            pointApi
                .getPointsGroupTable(filter, sort, pagination, data)
                .then(res => {
                    const response = {
                        items: res.getItemsList(),
                        pagination: res.getParams()?.getPagination()?.getCnt()
                    }

                    commit('setEarnedPointsGroupTable', response)
                    commit('setIsEarnedPointsGroupTable', false)

                    resolve(response)
                })
                .catch(err => {
                    commit('setEarnedPointsGroupTable', [])
                    commit('setIsEarnedPointsGroupTable', false)

                    reject(err)
                })
        })
    },

    getMinMaxPointDate({ commit }, data) {
        return new Promise((resolve, reject) => {
            pointApi
                .getMinMaxPointDate(data)
                .then(res => {
                    commit('setMinMaxPointDate', res)

                    resolve(res)
                })
                .catch(err => {
                    commit('setMinMaxPointDate', [])

                    reject(err)
                })
        })
    },
}

const dashboardPointModule = {
    state: new State(),
    mutations: mutations,
    actions: actions,
    getters: getters,
}

export default dashboardPointModule