/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: social_network_load.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as social_network_load_pb from './social_network_load_pb'; // proto import: "social_network_load.proto"


export class SocialNetworkLoadServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListSocialNetworkLoad = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkLoadService/ListSocialNetworkLoad',
    grpcWeb.MethodType.UNARY,
    social_network_load_pb.ListSocialNetworkLoadRequest,
    social_network_load_pb.ListSocialNetworkLoadResponse,
    (request: social_network_load_pb.ListSocialNetworkLoadRequest) => {
      return request.serializeBinary();
    },
    social_network_load_pb.ListSocialNetworkLoadResponse.deserializeBinary
  );

  listSocialNetworkLoad(
    request: social_network_load_pb.ListSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_load_pb.ListSocialNetworkLoadResponse>;

  listSocialNetworkLoad(
    request: social_network_load_pb.ListSocialNetworkLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_load_pb.ListSocialNetworkLoadResponse) => void): grpcWeb.ClientReadableStream<social_network_load_pb.ListSocialNetworkLoadResponse>;

  listSocialNetworkLoad(
    request: social_network_load_pb.ListSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_load_pb.ListSocialNetworkLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkLoadService/ListSocialNetworkLoad',
        request,
        metadata || {},
        this.methodDescriptorListSocialNetworkLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkLoadService/ListSocialNetworkLoad',
    request,
    metadata || {},
    this.methodDescriptorListSocialNetworkLoad);
  }

  methodDescriptorLoadSocialNetworkLoad = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkLoadService/LoadSocialNetworkLoad',
    grpcWeb.MethodType.UNARY,
    social_network_load_pb.LoadSocialNetworkLoadRequest,
    social_network_load_pb.LoadSocialNetworkLoadResponse,
    (request: social_network_load_pb.LoadSocialNetworkLoadRequest) => {
      return request.serializeBinary();
    },
    social_network_load_pb.LoadSocialNetworkLoadResponse.deserializeBinary
  );

  loadSocialNetworkLoad(
    request: social_network_load_pb.LoadSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_load_pb.LoadSocialNetworkLoadResponse>;

  loadSocialNetworkLoad(
    request: social_network_load_pb.LoadSocialNetworkLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_load_pb.LoadSocialNetworkLoadResponse) => void): grpcWeb.ClientReadableStream<social_network_load_pb.LoadSocialNetworkLoadResponse>;

  loadSocialNetworkLoad(
    request: social_network_load_pb.LoadSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_load_pb.LoadSocialNetworkLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkLoadService/LoadSocialNetworkLoad',
        request,
        metadata || {},
        this.methodDescriptorLoadSocialNetworkLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkLoadService/LoadSocialNetworkLoad',
    request,
    metadata || {},
    this.methodDescriptorLoadSocialNetworkLoad);
  }

  methodDescriptorCheckSocialNetworkLoad = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkLoadService/CheckSocialNetworkLoad',
    grpcWeb.MethodType.UNARY,
    social_network_load_pb.CheckSocialNetworkLoadRequest,
    social_network_load_pb.CheckSocialNetworkLoadResponse,
    (request: social_network_load_pb.CheckSocialNetworkLoadRequest) => {
      return request.serializeBinary();
    },
    social_network_load_pb.CheckSocialNetworkLoadResponse.deserializeBinary
  );

  checkSocialNetworkLoad(
    request: social_network_load_pb.CheckSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_load_pb.CheckSocialNetworkLoadResponse>;

  checkSocialNetworkLoad(
    request: social_network_load_pb.CheckSocialNetworkLoadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_load_pb.CheckSocialNetworkLoadResponse) => void): grpcWeb.ClientReadableStream<social_network_load_pb.CheckSocialNetworkLoadResponse>;

  checkSocialNetworkLoad(
    request: social_network_load_pb.CheckSocialNetworkLoadRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_load_pb.CheckSocialNetworkLoadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkLoadService/CheckSocialNetworkLoad',
        request,
        metadata || {},
        this.methodDescriptorCheckSocialNetworkLoad,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkLoadService/CheckSocialNetworkLoad',
    request,
    metadata || {},
    this.methodDescriptorCheckSocialNetworkLoad);
  }

}

