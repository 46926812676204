/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: answer_class.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as answer_class_pb from './answer_class_pb'; // proto import: "answer_class.proto"


export class AnswerClassServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/CreateAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.CreateAnswerClassRequest,
    answer_class_pb.CreateAnswerClassResponse,
    (request: answer_class_pb.CreateAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.CreateAnswerClassResponse.deserializeBinary
  );

  createAnswerClass(
    request: answer_class_pb.CreateAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.CreateAnswerClassResponse>;

  createAnswerClass(
    request: answer_class_pb.CreateAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.CreateAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.CreateAnswerClassResponse>;

  createAnswerClass(
    request: answer_class_pb.CreateAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.CreateAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/CreateAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorCreateAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/CreateAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorCreateAnswerClass);
  }

  methodDescriptorUpdateAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/UpdateAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.UpdateAnswerClassRequest,
    answer_class_pb.UpdateAnswerClassResponse,
    (request: answer_class_pb.UpdateAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.UpdateAnswerClassResponse.deserializeBinary
  );

  updateAnswerClass(
    request: answer_class_pb.UpdateAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.UpdateAnswerClassResponse>;

  updateAnswerClass(
    request: answer_class_pb.UpdateAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.UpdateAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.UpdateAnswerClassResponse>;

  updateAnswerClass(
    request: answer_class_pb.UpdateAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.UpdateAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/UpdateAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorUpdateAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/UpdateAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorUpdateAnswerClass);
  }

  methodDescriptorDeleteAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/DeleteAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.DeleteAnswerClassRequest,
    answer_class_pb.DeleteAnswerClassResponse,
    (request: answer_class_pb.DeleteAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.DeleteAnswerClassResponse.deserializeBinary
  );

  deleteAnswerClass(
    request: answer_class_pb.DeleteAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.DeleteAnswerClassResponse>;

  deleteAnswerClass(
    request: answer_class_pb.DeleteAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.DeleteAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.DeleteAnswerClassResponse>;

  deleteAnswerClass(
    request: answer_class_pb.DeleteAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.DeleteAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/DeleteAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorDeleteAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/DeleteAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorDeleteAnswerClass);
  }

  methodDescriptorListAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/ListAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.ListAnswerClassRequest,
    answer_class_pb.ListAnswerClassResponse,
    (request: answer_class_pb.ListAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.ListAnswerClassResponse.deserializeBinary
  );

  listAnswerClass(
    request: answer_class_pb.ListAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.ListAnswerClassResponse>;

  listAnswerClass(
    request: answer_class_pb.ListAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.ListAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.ListAnswerClassResponse>;

  listAnswerClass(
    request: answer_class_pb.ListAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.ListAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/ListAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorListAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/ListAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorListAnswerClass);
  }

  methodDescriptorSaveAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/SaveAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.SaveAnswerClassRequest,
    answer_class_pb.SaveAnswerClassResponse,
    (request: answer_class_pb.SaveAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.SaveAnswerClassResponse.deserializeBinary
  );

  saveAnswerClass(
    request: answer_class_pb.SaveAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.SaveAnswerClassResponse>;

  saveAnswerClass(
    request: answer_class_pb.SaveAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.SaveAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.SaveAnswerClassResponse>;

  saveAnswerClass(
    request: answer_class_pb.SaveAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.SaveAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/SaveAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorSaveAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/SaveAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorSaveAnswerClass);
  }

  methodDescriptorRemoveAnswerClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerClassService/RemoveAnswerClass',
    grpcWeb.MethodType.UNARY,
    answer_class_pb.RemoveAnswerClassRequest,
    answer_class_pb.RemoveAnswerClassResponse,
    (request: answer_class_pb.RemoveAnswerClassRequest) => {
      return request.serializeBinary();
    },
    answer_class_pb.RemoveAnswerClassResponse.deserializeBinary
  );

  removeAnswerClass(
    request: answer_class_pb.RemoveAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_class_pb.RemoveAnswerClassResponse>;

  removeAnswerClass(
    request: answer_class_pb.RemoveAnswerClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_class_pb.RemoveAnswerClassResponse) => void): grpcWeb.ClientReadableStream<answer_class_pb.RemoveAnswerClassResponse>;

  removeAnswerClass(
    request: answer_class_pb.RemoveAnswerClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_class_pb.RemoveAnswerClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerClassService/RemoveAnswerClass',
        request,
        metadata || {},
        this.methodDescriptorRemoveAnswerClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerClassService/RemoveAnswerClass',
    request,
    metadata || {},
    this.methodDescriptorRemoveAnswerClass);
  }

}

