import { ClientReadableStream } from 'grpc-web';
import { AccountsServiceClient } from '../../proto/pb/AccountsServiceClientPb'
import {
  Accounts,
  AccountsFilterItem,
  AccountsFilter,
  AccountsSort,
  AccountsPagination,
  ItemAccountsRequest,
  ListAccountsRequest,
  LoadPersonalListFromFileRequest,
  ListAccountsResponse,
} from '../../proto/pb/accounts_pb.js'
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

interface dataAccount {
  accountTypeId: any
  accountBirthday: any
  accountFullName: any
  accountSex: any
  accountContacts: any
  accountRating: any
  accountPoints: any
  accountsGuid?: any
}

const defaultLimit: number = 1000

export class AccountsApi {
  Account: Array<Accounts>
  client: AccountsServiceClient
  listAccountsRequest: ClientReadableStream<ListAccountsResponse> | null = null

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? ''
    this.client = new AccountsServiceClient(host, null, { unaryInterceptors: [new RefreshTokenInterceptor()] })
  }

  createFilter(data: any) {
    const filter = new AccountsFilter()
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && '' !== data[i].field_name) {
          const fItem = new AccountsFilterItem()
          fItem.setFieldName(data[i].field_name.toString())
          fItem.setOperand(
            data[i].operand !== undefined ? data[i].operand : '='
          )
          fItem.setValue(
            data[i].value !== undefined ? data[i].value.toString() : ''
          )
          fItem.setOr(this.createFilter(data[i].or)) // Рекурсия !!!
          filter.addItems(fItem)
        }
      }
    }
    return filter
  }

  createSort(data: any) {
    const sort = new AccountsSort()
    const name: string =
      data.name !== undefined ? data.name.toString() : 'a.account_guid'
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc'

    // Устанавливаем параметры сортировки
    sort.setName(name)
    sort.setExp(exp)

    return sort
  }

  createPagination(data: any) {
    const pagination = new AccountsPagination()
    const page: number = data.page !== undefined ? Number(data.page) : 1
    const limit: number =
      data.limit !== undefined ? Number(data.limit) : defaultLimit
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0

    // Устанавливаем пагинацию
    pagination.setPage(page)
    pagination.setLimit(limit)
    pagination.setPages(pages)
    pagination.setCnt(cnt)

    return pagination
  }

  getAccountsResultItems(filter: any, sort: any, pagination: any, data: any) {

    if (this.listAccountsRequest) {
      this.listAccountsRequest.cancel()
      this.listAccountsRequest = null
    }
    const request = new ListAccountsRequest()

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter))
    request.setSort(this.createSort(sort))
    request.setPagination(this.createPagination(pagination))
    request.setPersonalList(data.personal)

    console.log(
      '🚀 ~ file: AccountsApi.ts:88 ~ AccountsApi ~ getAccountsResultItems ~ request filter:',
      filter,
      'request',
      request
    )

    // Метод вроде публичный и не требует авторизации, проверял что без авторизации работает,
    // если нужна будет авторизация, то дополнительно обработку повторного запроса надо будет сделать в случае ошибки
    return new Promise((resolve, reject) => {
      this.listAccountsRequest = this.client.listAccounts(request, { Authorization: localStorage.getItem('access-key') || '' }, (err, res) => {
        if (res) resolve(res)
        if (err) {
          console.log('%c[ERROR]:List accounts', 'color:red', err)
          reject(err)
        }
      })
    })
  }

  getAccountListItem(filter: any) {
    const request = new ItemAccountsRequest()

    request.setFilter(this.createFilter(filter))

    return this.client.itemAccounts(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getSearchAccountList(filter: any, pagination: any) {
    const request = new ListAccountsRequest()

    request.setFilter(this.createFilter(filter))
    request.setPagination(this.createPagination(pagination))
    request.setSort(this.createSort({}))

    return this.client.listSearchAccounts(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  loadPersonalListFromFile(data: any) {
    const request = new LoadPersonalListFromFileRequest()
    request
      .setBase64File(data.b64)

    console.log('base64 req', request)

    return this.client.loadPersonalListFromFile(request, { Authorization: localStorage.getItem('access-key') } as any)
  }

  getExportAccountsTable(filter: any, sort: any, pagination: any, data: any) {
    const request = new ListAccountsRequest()

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter))
    request.setSort(this.createSort(sort))
    request.setPagination(this.createPagination(pagination))
    request.setPersonalList(data.personal)

    console.log(
      'export table request',
      request
    )

    return this.client.exportListAccounts(request, { Authorization: localStorage.getItem('access-key') } as any)
  }
}
