// source: answer_src.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.proto.AnswerSrc', null, global);
goog.exportSymbol('proto.proto.AnswerSrcFilter', null, global);
goog.exportSymbol('proto.proto.AnswerSrcFilterItem', null, global);
goog.exportSymbol('proto.proto.AnswerSrcInClass', null, global);
goog.exportSymbol('proto.proto.AnswerSrcPagination', null, global);
goog.exportSymbol('proto.proto.AnswerSrcSort', null, global);
goog.exportSymbol('proto.proto.AnswerSrcSource', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcInClassRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcInClassResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerSrcSourceResponse', null, global);
goog.exportSymbol('proto.proto.PersonalListAnswerSrcSourceRequest', null, global);
goog.exportSymbol('proto.proto.PersonalListAnswerSrcSourceResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrc.displayName = 'proto.proto.AnswerSrc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcSource = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrcSource, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcSource.displayName = 'proto.proto.AnswerSrcSource';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcInClass = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrcInClass, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcInClass.displayName = 'proto.proto.AnswerSrcInClass';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrcFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcFilterItem.displayName = 'proto.proto.AnswerSrcFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AnswerSrcFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AnswerSrcFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcFilter.displayName = 'proto.proto.AnswerSrcFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrcSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcSort.displayName = 'proto.proto.AnswerSrcSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerSrcPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerSrcPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerSrcPagination.displayName = 'proto.proto.AnswerSrcPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerSrcRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcRequest.displayName = 'proto.proto.ListAnswerSrcRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerSrcResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerSrcResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcResponse.displayName = 'proto.proto.ListAnswerSrcResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcSourceRequest.displayName = 'proto.proto.ListAnswerSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerSrcSourceResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcSourceResponse.displayName = 'proto.proto.ListAnswerSrcSourceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcInClassRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerSrcInClassRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcInClassRequest.displayName = 'proto.proto.ListAnswerSrcInClassRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerSrcInClassResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerSrcInClassResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerSrcInClassResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerSrcInClassResponse.displayName = 'proto.proto.ListAnswerSrcInClassResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListAnswerSrcSourceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListAnswerSrcSourceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListAnswerSrcSourceRequest.displayName = 'proto.proto.PersonalListAnswerSrcSourceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.PersonalListAnswerSrcSourceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.PersonalListAnswerSrcSourceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.PersonalListAnswerSrcSourceResponse.displayName = 'proto.proto.PersonalListAnswerSrcSourceResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrc.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrc.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerSrcId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerSrcText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerSrcTime: (f = msg.getAnswerSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrc}
 */
proto.proto.AnswerSrc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrc;
  return proto.proto.AnswerSrc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrc}
 */
proto.proto.AnswerSrc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerSrcId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerSrcText(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnswerSrcTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerSrcId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerSrcText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerSrcTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 answer_src_id = 1;
 * @return {number}
 */
proto.proto.AnswerSrc.prototype.getAnswerSrcId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrc} returns this
 */
proto.proto.AnswerSrc.prototype.setAnswerSrcId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_id = 2;
 * @return {number}
 */
proto.proto.AnswerSrc.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrc} returns this
 */
proto.proto.AnswerSrc.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_guid = 3;
 * @return {string}
 */
proto.proto.AnswerSrc.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrc} returns this
 */
proto.proto.AnswerSrc.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string answer_src_text = 4;
 * @return {string}
 */
proto.proto.AnswerSrc.prototype.getAnswerSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrc} returns this
 */
proto.proto.AnswerSrc.prototype.setAnswerSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp answer_src_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.AnswerSrc.prototype.getAnswerSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.AnswerSrc} returns this
*/
proto.proto.AnswerSrc.prototype.setAnswerSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerSrc} returns this
 */
proto.proto.AnswerSrc.prototype.clearAnswerSrcTime = function() {
  return this.setAnswerSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerSrc.prototype.hasAnswerSrcTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcSource.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcSource.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcSource} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcSource.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerSrcId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerSrcText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerSrcTime: (f = msg.getAnswerSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcSource}
 */
proto.proto.AnswerSrcSource.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcSource;
  return proto.proto.AnswerSrcSource.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcSource} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcSource}
 */
proto.proto.AnswerSrcSource.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerSrcId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerSrcText(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnswerSrcTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcSource.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcSource.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcSource} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcSource.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerSrcId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerSrcText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerSrcTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 answer_src_id = 1;
 * @return {number}
 */
proto.proto.AnswerSrcSource.prototype.getAnswerSrcId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcSource} returns this
 */
proto.proto.AnswerSrcSource.prototype.setAnswerSrcId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string account_guid = 2;
 * @return {string}
 */
proto.proto.AnswerSrcSource.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcSource} returns this
 */
proto.proto.AnswerSrcSource.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sso_id = 3;
 * @return {string}
 */
proto.proto.AnswerSrcSource.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcSource} returns this
 */
proto.proto.AnswerSrcSource.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string answer_src_text = 4;
 * @return {string}
 */
proto.proto.AnswerSrcSource.prototype.getAnswerSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcSource} returns this
 */
proto.proto.AnswerSrcSource.prototype.setAnswerSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp answer_src_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.AnswerSrcSource.prototype.getAnswerSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.AnswerSrcSource} returns this
*/
proto.proto.AnswerSrcSource.prototype.setAnswerSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerSrcSource} returns this
 */
proto.proto.AnswerSrcSource.prototype.clearAnswerSrcTime = function() {
  return this.setAnswerSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerSrcSource.prototype.hasAnswerSrcTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcInClass.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcInClass.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcInClass} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcInClass.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerSrcId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountGuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ssoId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerSrcText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerSrcTime: (f = msg.getAnswerSrcTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    pollTitle: jspb.Message.getFieldWithDefault(msg, 6, ""),
    questionStitle: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcInClass}
 */
proto.proto.AnswerSrcInClass.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcInClass;
  return proto.proto.AnswerSrcInClass.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcInClass} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcInClass}
 */
proto.proto.AnswerSrcInClass.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerSrcId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountGuid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSsoId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerSrcText(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAnswerSrcTime(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPollTitle(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuestionStitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcInClass.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcInClass.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcInClass} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcInClass.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerSrcId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAccountGuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSsoId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerSrcText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerSrcTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPollTitle();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQuestionStitle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 answer_src_id = 1;
 * @return {number}
 */
proto.proto.AnswerSrcInClass.prototype.getAnswerSrcId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setAnswerSrcId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string account_guid = 2;
 * @return {string}
 */
proto.proto.AnswerSrcInClass.prototype.getAccountGuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setAccountGuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sso_id = 3;
 * @return {string}
 */
proto.proto.AnswerSrcInClass.prototype.getSsoId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setSsoId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string answer_src_text = 4;
 * @return {string}
 */
proto.proto.AnswerSrcInClass.prototype.getAnswerSrcText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setAnswerSrcText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp answer_src_time = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.proto.AnswerSrcInClass.prototype.getAnswerSrcTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
*/
proto.proto.AnswerSrcInClass.prototype.setAnswerSrcTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.clearAnswerSrcTime = function() {
  return this.setAnswerSrcTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerSrcInClass.prototype.hasAnswerSrcTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string poll_title = 6;
 * @return {string}
 */
proto.proto.AnswerSrcInClass.prototype.getPollTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setPollTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string question_stitle = 7;
 * @return {string}
 */
proto.proto.AnswerSrcInClass.prototype.getQuestionStitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcInClass} returns this
 */
proto.proto.AnswerSrcInClass.prototype.setQuestionStitle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AnswerSrcFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcFilterItem}
 */
proto.proto.AnswerSrcFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcFilterItem;
  return proto.proto.AnswerSrcFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcFilterItem}
 */
proto.proto.AnswerSrcFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AnswerSrcFilter;
      reader.readMessage(value,proto.proto.AnswerSrcFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AnswerSrcFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AnswerSrcFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcFilterItem} returns this
 */
proto.proto.AnswerSrcFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AnswerSrcFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcFilterItem} returns this
 */
proto.proto.AnswerSrcFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AnswerSrcFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcFilterItem} returns this
 */
proto.proto.AnswerSrcFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AnswerSrcFilter or = 4;
 * @return {?proto.proto.AnswerSrcFilter}
 */
proto.proto.AnswerSrcFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AnswerSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcFilter, 4));
};


/**
 * @param {?proto.proto.AnswerSrcFilter|undefined} value
 * @return {!proto.proto.AnswerSrcFilterItem} returns this
*/
proto.proto.AnswerSrcFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerSrcFilterItem} returns this
 */
proto.proto.AnswerSrcFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerSrcFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AnswerSrcFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerSrcFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcFilter}
 */
proto.proto.AnswerSrcFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcFilter;
  return proto.proto.AnswerSrcFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcFilter}
 */
proto.proto.AnswerSrcFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcFilterItem;
      reader.readMessage(value,proto.proto.AnswerSrcFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerSrcFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerSrcFilterItem items = 1;
 * @return {!Array<!proto.proto.AnswerSrcFilterItem>}
 */
proto.proto.AnswerSrcFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerSrcFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerSrcFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerSrcFilterItem>} value
 * @return {!proto.proto.AnswerSrcFilter} returns this
*/
proto.proto.AnswerSrcFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerSrcFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerSrcFilterItem}
 */
proto.proto.AnswerSrcFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerSrcFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AnswerSrcFilter} returns this
 */
proto.proto.AnswerSrcFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcSort}
 */
proto.proto.AnswerSrcSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcSort;
  return proto.proto.AnswerSrcSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcSort}
 */
proto.proto.AnswerSrcSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.AnswerSrcSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcSort} returns this
 */
proto.proto.AnswerSrcSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.AnswerSrcSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerSrcSort} returns this
 */
proto.proto.AnswerSrcSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerSrcPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerSrcPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerSrcPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerSrcPagination}
 */
proto.proto.AnswerSrcPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerSrcPagination;
  return proto.proto.AnswerSrcPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerSrcPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerSrcPagination}
 */
proto.proto.AnswerSrcPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerSrcPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerSrcPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerSrcPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerSrcPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.AnswerSrcPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcPagination} returns this
 */
proto.proto.AnswerSrcPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.AnswerSrcPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcPagination} returns this
 */
proto.proto.AnswerSrcPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.AnswerSrcPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcPagination} returns this
 */
proto.proto.AnswerSrcPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.AnswerSrcPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerSrcPagination} returns this
 */
proto.proto.AnswerSrcPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerSrcPagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcRequest}
 */
proto.proto.ListAnswerSrcRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcRequest;
  return proto.proto.ListAnswerSrcRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcRequest}
 */
proto.proto.ListAnswerSrcRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcFilter;
      reader.readMessage(value,proto.proto.AnswerSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerSrcSort;
      reader.readMessage(value,proto.proto.AnswerSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerSrcPagination;
      reader.readMessage(value,proto.proto.AnswerSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerSrcPagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional AnswerSrcFilter filter = 1;
 * @return {?proto.proto.AnswerSrcFilter}
 */
proto.proto.ListAnswerSrcRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcFilter, 1));
};


/**
 * @param {?proto.proto.AnswerSrcFilter|undefined} value
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
*/
proto.proto.ListAnswerSrcRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
 */
proto.proto.ListAnswerSrcRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerSrcSort sort = 2;
 * @return {?proto.proto.AnswerSrcSort}
 */
proto.proto.ListAnswerSrcRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcSort, 2));
};


/**
 * @param {?proto.proto.AnswerSrcSort|undefined} value
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
*/
proto.proto.ListAnswerSrcRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
 */
proto.proto.ListAnswerSrcRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerSrcPagination pagination = 3;
 * @return {?proto.proto.AnswerSrcPagination}
 */
proto.proto.ListAnswerSrcRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcPagination, 3));
};


/**
 * @param {?proto.proto.AnswerSrcPagination|undefined} value
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
*/
proto.proto.ListAnswerSrcRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcRequest} returns this
 */
proto.proto.ListAnswerSrcRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerSrcResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerSrc.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerSrcRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcResponse}
 */
proto.proto.ListAnswerSrcResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcResponse;
  return proto.proto.ListAnswerSrcResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcResponse}
 */
proto.proto.ListAnswerSrcResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrc;
      reader.readMessage(value,proto.proto.AnswerSrc.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerSrcRequest;
      reader.readMessage(value,proto.proto.ListAnswerSrcRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerSrc.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerSrcRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerSrc items = 1;
 * @return {!Array<!proto.proto.AnswerSrc>}
 */
proto.proto.ListAnswerSrcResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerSrc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerSrc, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerSrc>} value
 * @return {!proto.proto.ListAnswerSrcResponse} returns this
*/
proto.proto.ListAnswerSrcResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerSrc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerSrc}
 */
proto.proto.ListAnswerSrcResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerSrc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerSrcResponse} returns this
 */
proto.proto.ListAnswerSrcResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerSrcRequest params = 2;
 * @return {?proto.proto.ListAnswerSrcRequest}
 */
proto.proto.ListAnswerSrcResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerSrcRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerSrcRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerSrcRequest|undefined} value
 * @return {!proto.proto.ListAnswerSrcResponse} returns this
*/
proto.proto.ListAnswerSrcResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcResponse} returns this
 */
proto.proto.ListAnswerSrcResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerSrcPagination.toObject(includeInstance, f),
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcSourceRequest}
 */
proto.proto.ListAnswerSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcSourceRequest;
  return proto.proto.ListAnswerSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcSourceRequest}
 */
proto.proto.ListAnswerSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcFilter;
      reader.readMessage(value,proto.proto.AnswerSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerSrcSort;
      reader.readMessage(value,proto.proto.AnswerSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerSrcPagination;
      reader.readMessage(value,proto.proto.AnswerSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerSrcPagination.serializeBinaryToWriter
    );
  }
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional AnswerSrcFilter filter = 1;
 * @return {?proto.proto.AnswerSrcFilter}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcFilter, 1));
};


/**
 * @param {?proto.proto.AnswerSrcFilter|undefined} value
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
*/
proto.proto.ListAnswerSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerSrcSort sort = 2;
 * @return {?proto.proto.AnswerSrcSort}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcSort, 2));
};


/**
 * @param {?proto.proto.AnswerSrcSort|undefined} value
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
*/
proto.proto.ListAnswerSrcSourceRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerSrcPagination pagination = 3;
 * @return {?proto.proto.AnswerSrcPagination}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcPagination, 3));
};


/**
 * @param {?proto.proto.AnswerSrcPagination|undefined} value
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
*/
proto.proto.ListAnswerSrcSourceRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 answer_grp_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool has_full = 5;
 * @return {boolean}
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListAnswerSrcSourceRequest} returns this
 */
proto.proto.ListAnswerSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerSrcSourceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerSrcSource.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerSrcSourceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcSourceResponse}
 */
proto.proto.ListAnswerSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcSourceResponse;
  return proto.proto.ListAnswerSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcSourceResponse}
 */
proto.proto.ListAnswerSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcSource;
      reader.readMessage(value,proto.proto.AnswerSrcSource.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerSrcSourceRequest;
      reader.readMessage(value,proto.proto.ListAnswerSrcSourceRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerSrcSource.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerSrcSourceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerSrcSource items = 1;
 * @return {!Array<!proto.proto.AnswerSrcSource>}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerSrcSource>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerSrcSource, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerSrcSource>} value
 * @return {!proto.proto.ListAnswerSrcSourceResponse} returns this
*/
proto.proto.ListAnswerSrcSourceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerSrcSource=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerSrcSource}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerSrcSource, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerSrcSourceResponse} returns this
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerSrcSourceRequest params = 2;
 * @return {?proto.proto.ListAnswerSrcSourceRequest}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerSrcSourceRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerSrcSourceRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerSrcSourceRequest|undefined} value
 * @return {!proto.proto.ListAnswerSrcSourceResponse} returns this
*/
proto.proto.ListAnswerSrcSourceResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcSourceResponse} returns this
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcSourceResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcInClassRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcInClassRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcInClassRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerSrcFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerSrcSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerSrcPagination.toObject(includeInstance, f),
    className: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcInClassRequest}
 */
proto.proto.ListAnswerSrcInClassRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcInClassRequest;
  return proto.proto.ListAnswerSrcInClassRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcInClassRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcInClassRequest}
 */
proto.proto.ListAnswerSrcInClassRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcFilter;
      reader.readMessage(value,proto.proto.AnswerSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerSrcSort;
      reader.readMessage(value,proto.proto.AnswerSrcSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerSrcPagination;
      reader.readMessage(value,proto.proto.AnswerSrcPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClassName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcInClassRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcInClassRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcInClassRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerSrcSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerSrcPagination.serializeBinaryToWriter
    );
  }
  f = message.getClassName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional AnswerSrcFilter filter = 1;
 * @return {?proto.proto.AnswerSrcFilter}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcFilter, 1));
};


/**
 * @param {?proto.proto.AnswerSrcFilter|undefined} value
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
*/
proto.proto.ListAnswerSrcInClassRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerSrcSort sort = 2;
 * @return {?proto.proto.AnswerSrcSort}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerSrcSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcSort, 2));
};


/**
 * @param {?proto.proto.AnswerSrcSort|undefined} value
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
*/
proto.proto.ListAnswerSrcInClassRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerSrcPagination pagination = 3;
 * @return {?proto.proto.AnswerSrcPagination}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerSrcPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcPagination, 3));
};


/**
 * @param {?proto.proto.AnswerSrcPagination|undefined} value
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
*/
proto.proto.ListAnswerSrcInClassRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string class_name = 4;
 * @return {string}
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.getClassName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.ListAnswerSrcInClassRequest} returns this
 */
proto.proto.ListAnswerSrcInClassRequest.prototype.setClassName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerSrcInClassResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerSrcInClassResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerSrcInClassResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcInClassResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerSrcInClass.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerSrcInClassRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerSrcInClassResponse}
 */
proto.proto.ListAnswerSrcInClassResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerSrcInClassResponse;
  return proto.proto.ListAnswerSrcInClassResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerSrcInClassResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerSrcInClassResponse}
 */
proto.proto.ListAnswerSrcInClassResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcInClass;
      reader.readMessage(value,proto.proto.AnswerSrcInClass.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerSrcInClassRequest;
      reader.readMessage(value,proto.proto.ListAnswerSrcInClassRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerSrcInClassResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerSrcInClassResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerSrcInClassResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerSrcInClass.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerSrcInClassRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerSrcInClass items = 1;
 * @return {!Array<!proto.proto.AnswerSrcInClass>}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerSrcInClass>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerSrcInClass, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerSrcInClass>} value
 * @return {!proto.proto.ListAnswerSrcInClassResponse} returns this
*/
proto.proto.ListAnswerSrcInClassResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerSrcInClass=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerSrcInClass}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerSrcInClass, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerSrcInClassResponse} returns this
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerSrcInClassRequest params = 2;
 * @return {?proto.proto.ListAnswerSrcInClassRequest}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerSrcInClassRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerSrcInClassRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerSrcInClassRequest|undefined} value
 * @return {!proto.proto.ListAnswerSrcInClassResponse} returns this
*/
proto.proto.ListAnswerSrcInClassResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerSrcInClassResponse} returns this
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerSrcInClassResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListAnswerSrcSourceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListAnswerSrcSourceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListAnswerSrcSourceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerSrcFilter.toObject(includeInstance, f),
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    hasFull: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListAnswerSrcSourceRequest;
  return proto.proto.PersonalListAnswerSrcSourceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListAnswerSrcSourceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerSrcFilter;
      reader.readMessage(value,proto.proto.AnswerSrcFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasFull(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListAnswerSrcSourceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListAnswerSrcSourceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListAnswerSrcSourceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerSrcFilter.serializeBinaryToWriter
    );
  }
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getHasFull();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional AnswerSrcFilter filter = 1;
 * @return {?proto.proto.AnswerSrcFilter}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerSrcFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerSrcFilter, 1));
};


/**
 * @param {?proto.proto.AnswerSrcFilter|undefined} value
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest} returns this
*/
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest} returns this
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 answer_grp_id = 2;
 * @return {number}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest} returns this
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool has_full = 3;
 * @return {boolean}
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.getHasFull = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListAnswerSrcSourceRequest} returns this
 */
proto.proto.PersonalListAnswerSrcSourceRequest.prototype.setHasFull = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.PersonalListAnswerSrcSourceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.PersonalListAnswerSrcSourceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.PersonalListAnswerSrcSourceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListAnswerSrcSourceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.PersonalListAnswerSrcSourceResponse}
 */
proto.proto.PersonalListAnswerSrcSourceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.PersonalListAnswerSrcSourceResponse;
  return proto.proto.PersonalListAnswerSrcSourceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.PersonalListAnswerSrcSourceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.PersonalListAnswerSrcSourceResponse}
 */
proto.proto.PersonalListAnswerSrcSourceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.PersonalListAnswerSrcSourceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.PersonalListAnswerSrcSourceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.PersonalListAnswerSrcSourceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.PersonalListAnswerSrcSourceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.PersonalListAnswerSrcSourceResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.PersonalListAnswerSrcSourceResponse} returns this
 */
proto.proto.PersonalListAnswerSrcSourceResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
