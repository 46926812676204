import {
  ProjectsServiceClient
} from '../../proto/pb/ProjectsServiceClientPb';
import {
  Projects,
  ListProjectsRequest,
  //ItemProjectsRequest,
  ProjectsFilter,
  ProjectsFilterItem,
  ProjectsSort,
  ProjectsPagination,
  CreateProjectsRequest,
  UpdateProjectsRequest,
  DeleteProjectsRequest
} from '../../proto/pb/projects_pb';
import { RefreshTokenInterceptor } from "@/components/modules/users/services/api/RefreshTokenInterceptor";

// Количество на странице по-умолчанию
const defaultLimit: number = 100;

export class ProjectsApi {
  items: Array<Projects> = [];
  client: ProjectsServiceClient;
  metadata: any;

  constructor() {
    const host: string = (window as any).VUE_APP_API_URL ?? '';
    this.client = new ProjectsServiceClient(host, null, { unaryInterceptors: [new RefreshTokenInterceptor()] });
    this.metadata = {
      Authorization: localStorage.getItem('access-key')
    }
  }

  // Установка и получение фильтров
  createFilter(data: any) {

    const filter = new ProjectsFilter();

    // Устанавливаем фильтры
    if (data && data.length) {
      for (const i in data) {
        if (data[i].field_name && ('' !== data[i].field_name)) {
          const fItem = new ProjectsFilterItem();
          fItem.setFieldName(data[i].field_name.toString());
          fItem.setOperand(data[i].operand !== undefined ? data[i].operand.toString() : '=');
          fItem.setValue(data[i].value !== undefined ? data[i].value.toString() : '');
          fItem.setOr(this.createFilter(data[i].or)); // Рекурсия !!!
          filter.addItems(fItem);
        }
      }
    }

    return filter;
  }

  // Установка и получение сортировки
  createSort(data: any) {

    const sort = new ProjectsSort();
    const name: string = data.name !== undefined ? data.name.toString() : 'project_name';
    const exp: string = data.exp !== undefined ? data.exp.toString() : 'asc';

    // Устанавливаем параметры сортировки
    sort.setName(name);
    sort.setExp(exp);

    return sort;
  }

  // Установка и получение пагинации
  createPagination(data: any) {

    const pagination = new ProjectsPagination();
    const page: number = data.page !== undefined ? Number(data.page) : 1;
    const limit: number = data.limit !== undefined ? Number(data.limit) : defaultLimit;
    const pages: number = data.pages !== undefined ? Number(data.pages) : 0;
    const cnt: number = data.cnt !== undefined ? Number(data.cnt) : 0;

    // Устанавливаем пагинацию
    pagination.setPage(page);
    pagination.setLimit(limit);
    pagination.setPages(pages);

    return pagination;
  }

  // Список элементов
  getProjectsItems(filter: any, sort: any, pagination: any) {

    const request = new ListProjectsRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort(sort));
    request.setPagination(this.createPagination(pagination));

    return this.client.listProjects(request, this.metadata);
  }

  // Получение элемента
  getProjectsItem(filter: any) {

    const request = new ListProjectsRequest();

    // Устанавливаем параметры запроса
    request.setFilter(this.createFilter(filter));
    request.setSort(this.createSort({}));
    request.setPagination(this.createPagination({}));

    return this.client.listProjects(request, this.metadata)
      .then((message) => {
        const itemList = message.getItemsList()

        if (itemList && (itemList.length > 0)) {
          return itemList[0]
        }
      })
  }

  // Создание элемента
  createProjectsItem(data: any) {

    const request = new CreateProjectsRequest();
    request.setProjectName(data.name);
    request.setProjectShortName(data.short_name);

    return this.client.createProjects(request, this.metadata);
  }

  // Редактирование элемента
  updateProjectsItem(data: any) {

    const request = new UpdateProjectsRequest();
    request.setProjectId(data.id);
    request.setProjectName(data.name);
    request.setProjectShortName(data.short_name);

    return this.client.updateProjects(request, this.metadata);
  }

  // Удаление элемента
  deleteProjectsItem(data: any) {

    const request = new DeleteProjectsRequest();
    request.setProjectId(data.id);

    return this.client.deleteProjects(request, this.metadata);
  }
}
