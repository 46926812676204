import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { AuthApi as authApi } from '../services/api/AuthApi';
import { computedInject, RemovableRef } from "@vueuse/core";
import store from "@/store";

// Создаем объект
// const authApi = new AuthApi();

// Создаем состояние
class State {
  isAuth: boolean
  accessToken: RemovableRef<string>
  refreshToken: RemovableRef<string>
  userRole: any
}

// Создаем геттеры
const getters = <GetterTree<State, any>>{
  getIsAuthSuccess(state): unknown {
    return state.isAuth;
  },

  getAccessToken(state) {
    return state.accessToken
  },

  getRefreshToken(state) {
    return state.refreshToken
  },

  getUserRole(state) {
    return state.userRole
  },
}

// Создаем мутации
const mutations = <MutationTree<State>>{
  setIsAuthSuccess(state: State, payload: boolean) {
    state.isAuth = payload;
  },

  setAccessToken(state, payload) {
    state.accessToken = payload
  },

  setRefreshToken(state, payload) {
    state.refreshToken = payload
  },

  setUserRole(state, payload) {
    state.userRole = payload
  },
}

// Создаем действия
const actions = <ActionTree<any, any>>{
  doUserAuth({ commit }, data: any) {
    return new Promise((resolve, reject) => {

      // Проверка логина и пароля
      authApi.checkUserCreds(data)
        .then((res: any) => {
          commit('setIsAuthSuccess', true)
          store.dispatch('setToken', res.getAccessToken())
          store.dispatch('setRefreshToken', res.getRefreshToken())
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
          reject(err)
        })
    });
  },

  setToken({ commit }, token: string) {
    localStorage.setItem('access-key', token);
    commit('setAccessToken', token)
  },

  removeToken() {
    localStorage.removeItem('access-key')
  },

  setRefreshToken({ commit }, token: string) {
    localStorage.setItem('refresh-key', token)
    commit('setRefreshToken', token)
  },

  removeRefreshToken() {
    localStorage.removeItem('refresh-key')
  },

  logout({ commit }) {
    store.dispatch('removeRefreshToken')
    store.dispatch('removeToken')
    commit('setIsAuthSuccess', false)
    location.reload()
  },
}

// Создаем модуль
const AuthModule = {
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters
};

export default AuthModule;
