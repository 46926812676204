/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: projects.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as projects_pb from './projects_pb'; // proto import: "projects.proto"


export class ProjectsServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateProjects = new grpcWeb.MethodDescriptor(
    '/proto.ProjectsService/CreateProjects',
    grpcWeb.MethodType.UNARY,
    projects_pb.CreateProjectsRequest,
    projects_pb.CreateProjectsResponse,
    (request: projects_pb.CreateProjectsRequest) => {
      return request.serializeBinary();
    },
    projects_pb.CreateProjectsResponse.deserializeBinary
  );

  createProjects(
    request: projects_pb.CreateProjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projects_pb.CreateProjectsResponse>;

  createProjects(
    request: projects_pb.CreateProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projects_pb.CreateProjectsResponse) => void): grpcWeb.ClientReadableStream<projects_pb.CreateProjectsResponse>;

  createProjects(
    request: projects_pb.CreateProjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projects_pb.CreateProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ProjectsService/CreateProjects',
        request,
        metadata || {},
        this.methodDescriptorCreateProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ProjectsService/CreateProjects',
    request,
    metadata || {},
    this.methodDescriptorCreateProjects);
  }

  methodDescriptorUpdateProjects = new grpcWeb.MethodDescriptor(
    '/proto.ProjectsService/UpdateProjects',
    grpcWeb.MethodType.UNARY,
    projects_pb.UpdateProjectsRequest,
    projects_pb.UpdateProjectsResponse,
    (request: projects_pb.UpdateProjectsRequest) => {
      return request.serializeBinary();
    },
    projects_pb.UpdateProjectsResponse.deserializeBinary
  );

  updateProjects(
    request: projects_pb.UpdateProjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projects_pb.UpdateProjectsResponse>;

  updateProjects(
    request: projects_pb.UpdateProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projects_pb.UpdateProjectsResponse) => void): grpcWeb.ClientReadableStream<projects_pb.UpdateProjectsResponse>;

  updateProjects(
    request: projects_pb.UpdateProjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projects_pb.UpdateProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ProjectsService/UpdateProjects',
        request,
        metadata || {},
        this.methodDescriptorUpdateProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ProjectsService/UpdateProjects',
    request,
    metadata || {},
    this.methodDescriptorUpdateProjects);
  }

  methodDescriptorDeleteProjects = new grpcWeb.MethodDescriptor(
    '/proto.ProjectsService/DeleteProjects',
    grpcWeb.MethodType.UNARY,
    projects_pb.DeleteProjectsRequest,
    projects_pb.DeleteProjectsResponse,
    (request: projects_pb.DeleteProjectsRequest) => {
      return request.serializeBinary();
    },
    projects_pb.DeleteProjectsResponse.deserializeBinary
  );

  deleteProjects(
    request: projects_pb.DeleteProjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projects_pb.DeleteProjectsResponse>;

  deleteProjects(
    request: projects_pb.DeleteProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projects_pb.DeleteProjectsResponse) => void): grpcWeb.ClientReadableStream<projects_pb.DeleteProjectsResponse>;

  deleteProjects(
    request: projects_pb.DeleteProjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projects_pb.DeleteProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ProjectsService/DeleteProjects',
        request,
        metadata || {},
        this.methodDescriptorDeleteProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ProjectsService/DeleteProjects',
    request,
    metadata || {},
    this.methodDescriptorDeleteProjects);
  }

  methodDescriptorListProjects = new grpcWeb.MethodDescriptor(
    '/proto.ProjectsService/ListProjects',
    grpcWeb.MethodType.UNARY,
    projects_pb.ListProjectsRequest,
    projects_pb.ListProjectsResponse,
    (request: projects_pb.ListProjectsRequest) => {
      return request.serializeBinary();
    },
    projects_pb.ListProjectsResponse.deserializeBinary
  );

  listProjects(
    request: projects_pb.ListProjectsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<projects_pb.ListProjectsResponse>;

  listProjects(
    request: projects_pb.ListProjectsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: projects_pb.ListProjectsResponse) => void): grpcWeb.ClientReadableStream<projects_pb.ListProjectsResponse>;

  listProjects(
    request: projects_pb.ListProjectsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: projects_pb.ListProjectsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.ProjectsService/ListProjects',
        request,
        metadata || {},
        this.methodDescriptorListProjects,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.ProjectsService/ListProjects',
    request,
    metadata || {},
    this.methodDescriptorListProjects);
  }

}

