/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: message_grp.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as message_grp_pb from './message_grp_pb'; // proto import: "message_grp.proto"


export class MessageGrpServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/CreateMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.CreateMessageGrpRequest,
    message_grp_pb.CreateMessageGrpResponse,
    (request: message_grp_pb.CreateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.CreateMessageGrpResponse.deserializeBinary
  );

  createMessageGrp(
    request: message_grp_pb.CreateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.CreateMessageGrpResponse>;

  createMessageGrp(
    request: message_grp_pb.CreateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.CreateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.CreateMessageGrpResponse>;

  createMessageGrp(
    request: message_grp_pb.CreateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.CreateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/CreateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorCreateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/CreateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorCreateMessageGrp);
  }

  methodDescriptorUpdateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/UpdateMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.UpdateMessageGrpRequest,
    message_grp_pb.UpdateMessageGrpResponse,
    (request: message_grp_pb.UpdateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.UpdateMessageGrpResponse.deserializeBinary
  );

  updateMessageGrp(
    request: message_grp_pb.UpdateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.UpdateMessageGrpResponse>;

  updateMessageGrp(
    request: message_grp_pb.UpdateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.UpdateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.UpdateMessageGrpResponse>;

  updateMessageGrp(
    request: message_grp_pb.UpdateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.UpdateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/UpdateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorUpdateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/UpdateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorUpdateMessageGrp);
  }

  methodDescriptorUpdateTextMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/UpdateTextMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.UpdateTextMessageGrpRequest,
    message_grp_pb.UpdateTextMessageGrpResponse,
    (request: message_grp_pb.UpdateTextMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.UpdateTextMessageGrpResponse.deserializeBinary
  );

  updateTextMessageGrp(
    request: message_grp_pb.UpdateTextMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.UpdateTextMessageGrpResponse>;

  updateTextMessageGrp(
    request: message_grp_pb.UpdateTextMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.UpdateTextMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.UpdateTextMessageGrpResponse>;

  updateTextMessageGrp(
    request: message_grp_pb.UpdateTextMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.UpdateTextMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/UpdateTextMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorUpdateTextMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/UpdateTextMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorUpdateTextMessageGrp);
  }

  methodDescriptorDeleteMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/DeleteMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.DeleteMessageGrpRequest,
    message_grp_pb.DeleteMessageGrpResponse,
    (request: message_grp_pb.DeleteMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.DeleteMessageGrpResponse.deserializeBinary
  );

  deleteMessageGrp(
    request: message_grp_pb.DeleteMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.DeleteMessageGrpResponse>;

  deleteMessageGrp(
    request: message_grp_pb.DeleteMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.DeleteMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.DeleteMessageGrpResponse>;

  deleteMessageGrp(
    request: message_grp_pb.DeleteMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.DeleteMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/DeleteMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorDeleteMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/DeleteMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorDeleteMessageGrp);
  }

  methodDescriptorListMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/ListMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.ListMessageGrpRequest,
    message_grp_pb.ListMessageGrpResponse,
    (request: message_grp_pb.ListMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.ListMessageGrpResponse.deserializeBinary
  );

  listMessageGrp(
    request: message_grp_pb.ListMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.ListMessageGrpResponse>;

  listMessageGrp(
    request: message_grp_pb.ListMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.ListMessageGrpResponse>;

  listMessageGrp(
    request: message_grp_pb.ListMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/ListMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorListMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/ListMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorListMessageGrp);
  }

  methodDescriptorListMessageGrpWithFindMessage = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/ListMessageGrpWithFindMessage',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.ListMessageGrpWithFindMessageRequest,
    message_grp_pb.ListMessageGrpWithFindMessageResponse,
    (request: message_grp_pb.ListMessageGrpWithFindMessageRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.ListMessageGrpWithFindMessageResponse.deserializeBinary
  );

  listMessageGrpWithFindMessage(
    request: message_grp_pb.ListMessageGrpWithFindMessageRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.ListMessageGrpWithFindMessageResponse>;

  listMessageGrpWithFindMessage(
    request: message_grp_pb.ListMessageGrpWithFindMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpWithFindMessageResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.ListMessageGrpWithFindMessageResponse>;

  listMessageGrpWithFindMessage(
    request: message_grp_pb.ListMessageGrpWithFindMessageRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpWithFindMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/ListMessageGrpWithFindMessage',
        request,
        metadata || {},
        this.methodDescriptorListMessageGrpWithFindMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/ListMessageGrpWithFindMessage',
    request,
    metadata || {},
    this.methodDescriptorListMessageGrpWithFindMessage);
  }

  methodDescriptorListMessageGrpChilds = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/ListMessageGrpChilds',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.ListMessageGrpChildsRequest,
    message_grp_pb.ListMessageGrpChildsResponse,
    (request: message_grp_pb.ListMessageGrpChildsRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.ListMessageGrpChildsResponse.deserializeBinary
  );

  listMessageGrpChilds(
    request: message_grp_pb.ListMessageGrpChildsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.ListMessageGrpChildsResponse>;

  listMessageGrpChilds(
    request: message_grp_pb.ListMessageGrpChildsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpChildsResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.ListMessageGrpChildsResponse>;

  listMessageGrpChilds(
    request: message_grp_pb.ListMessageGrpChildsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.ListMessageGrpChildsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/ListMessageGrpChilds',
        request,
        metadata || {},
        this.methodDescriptorListMessageGrpChilds,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/ListMessageGrpChilds',
    request,
    metadata || {},
    this.methodDescriptorListMessageGrpChilds);
  }

  methodDescriptorReduceMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/ReduceMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.ReduceMessageGrpRequest,
    message_grp_pb.ReduceMessageGrpResponse,
    (request: message_grp_pb.ReduceMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.ReduceMessageGrpResponse.deserializeBinary
  );

  reduceMessageGrp(
    request: message_grp_pb.ReduceMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.ReduceMessageGrpResponse>;

  reduceMessageGrp(
    request: message_grp_pb.ReduceMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.ReduceMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.ReduceMessageGrpResponse>;

  reduceMessageGrp(
    request: message_grp_pb.ReduceMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.ReduceMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/ReduceMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorReduceMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/ReduceMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorReduceMessageGrp);
  }

  methodDescriptorSplitMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/SplitMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.SplitMessageGrpRequest,
    message_grp_pb.SplitMessageGrpResponse,
    (request: message_grp_pb.SplitMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.SplitMessageGrpResponse.deserializeBinary
  );

  splitMessageGrp(
    request: message_grp_pb.SplitMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.SplitMessageGrpResponse>;

  splitMessageGrp(
    request: message_grp_pb.SplitMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.SplitMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.SplitMessageGrpResponse>;

  splitMessageGrp(
    request: message_grp_pb.SplitMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.SplitMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/SplitMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSplitMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/SplitMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSplitMessageGrp);
  }

  methodDescriptorSeparateMessageGrp = new grpcWeb.MethodDescriptor(
    '/proto.MessageGrpService/SeparateMessageGrp',
    grpcWeb.MethodType.UNARY,
    message_grp_pb.SeparateMessageGrpRequest,
    message_grp_pb.SeparateMessageGrpResponse,
    (request: message_grp_pb.SeparateMessageGrpRequest) => {
      return request.serializeBinary();
    },
    message_grp_pb.SeparateMessageGrpResponse.deserializeBinary
  );

  separateMessageGrp(
    request: message_grp_pb.SeparateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_grp_pb.SeparateMessageGrpResponse>;

  separateMessageGrp(
    request: message_grp_pb.SeparateMessageGrpRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_grp_pb.SeparateMessageGrpResponse) => void): grpcWeb.ClientReadableStream<message_grp_pb.SeparateMessageGrpResponse>;

  separateMessageGrp(
    request: message_grp_pb.SeparateMessageGrpRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_grp_pb.SeparateMessageGrpResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageGrpService/SeparateMessageGrp',
        request,
        metadata || {},
        this.methodDescriptorSeparateMessageGrp,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageGrpService/SeparateMessageGrp',
    request,
    metadata || {},
    this.methodDescriptorSeparateMessageGrp);
  }

}

