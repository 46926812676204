// source: answer_grp_cls.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.AnswerGrpClsFilter', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsFilterItem', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsIn', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsOut', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsPagination', null, global);
goog.exportSymbol('proto.proto.AnswerGrpClsSort', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsInRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsInResponse', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsOutRequest', null, global);
goog.exportSymbol('proto.proto.ListAnswerGrpClsOutResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsIn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsIn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsIn.displayName = 'proto.proto.AnswerGrpClsIn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsOut = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsOut, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsOut.displayName = 'proto.proto.AnswerGrpClsOut';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsFilterItem.displayName = 'proto.proto.AnswerGrpClsFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.AnswerGrpClsFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.AnswerGrpClsFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsFilter.displayName = 'proto.proto.AnswerGrpClsFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsSort.displayName = 'proto.proto.AnswerGrpClsSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.AnswerGrpClsPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.AnswerGrpClsPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.AnswerGrpClsPagination.displayName = 'proto.proto.AnswerGrpClsPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsInRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsInRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsInRequest.displayName = 'proto.proto.ListAnswerGrpClsInRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsInResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpClsInResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsInResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsInResponse.displayName = 'proto.proto.ListAnswerGrpClsInResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsOutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsOutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsOutRequest.displayName = 'proto.proto.ListAnswerGrpClsOutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.ListAnswerGrpClsOutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.ListAnswerGrpClsOutResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.ListAnswerGrpClsOutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.ListAnswerGrpClsOutResponse.displayName = 'proto.proto.ListAnswerGrpClsOutResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsIn.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsIn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsIn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsIn.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    answerGrpCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    answerGrpSum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsIn}
 */
proto.proto.AnswerGrpClsIn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsIn;
  return proto.proto.AnswerGrpClsIn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsIn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsIn}
 */
proto.proto.AnswerGrpClsIn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpCount(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpSum(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsIn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsIn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsIn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsIn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.AnswerGrpClsIn.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_id = 2;
 * @return {number}
 */
proto.proto.AnswerGrpClsIn.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string answer_grp_text = 3;
 * @return {string}
 */
proto.proto.AnswerGrpClsIn.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 answer_grp_count = 4;
 * @return {number}
 */
proto.proto.AnswerGrpClsIn.prototype.getAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 answer_grp_sum = 5;
 * @return {number}
 */
proto.proto.AnswerGrpClsIn.prototype.getAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 class_id = 6;
 * @return {number}
 */
proto.proto.AnswerGrpClsIn.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string notes = 7;
 * @return {string}
 */
proto.proto.AnswerGrpClsIn.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsIn} returns this
 */
proto.proto.AnswerGrpClsIn.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsOut.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsOut.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsOut} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsOut.toObject = function(includeInstance, msg) {
  var f, obj = {
    answerGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    answerId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    answerGrpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    answerGrpCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    answerGrpSum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    hasClasses: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsOut}
 */
proto.proto.AnswerGrpClsOut.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsOut;
  return proto.proto.AnswerGrpClsOut.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsOut} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsOut}
 */
proto.proto.AnswerGrpClsOut.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswerGrpText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpCount(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAnswerGrpSum(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasClasses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsOut.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsOut.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsOut} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsOut.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnswerGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAnswerId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAnswerGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAnswerGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getAnswerGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getHasClasses();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int64 answer_grp_id = 1;
 * @return {number}
 */
proto.proto.AnswerGrpClsOut.prototype.getAnswerGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setAnswerGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 answer_id = 3;
 * @return {number}
 */
proto.proto.AnswerGrpClsOut.prototype.getAnswerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setAnswerId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string answer_grp_text = 4;
 * @return {string}
 */
proto.proto.AnswerGrpClsOut.prototype.getAnswerGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setAnswerGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 answer_grp_count = 5;
 * @return {number}
 */
proto.proto.AnswerGrpClsOut.prototype.getAnswerGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setAnswerGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int64 answer_grp_sum = 6;
 * @return {number}
 */
proto.proto.AnswerGrpClsOut.prototype.getAnswerGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setAnswerGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool has_classes = 7;
 * @return {boolean}
 */
proto.proto.AnswerGrpClsOut.prototype.getHasClasses = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.AnswerGrpClsOut} returns this
 */
proto.proto.AnswerGrpClsOut.prototype.setHasClasses = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.AnswerGrpClsFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsFilterItem}
 */
proto.proto.AnswerGrpClsFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsFilterItem;
  return proto.proto.AnswerGrpClsFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsFilterItem}
 */
proto.proto.AnswerGrpClsFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.AnswerGrpClsFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.AnswerGrpClsFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFilterItem} returns this
 */
proto.proto.AnswerGrpClsFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFilterItem} returns this
 */
proto.proto.AnswerGrpClsFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsFilterItem} returns this
 */
proto.proto.AnswerGrpClsFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional AnswerGrpClsFilter or = 4;
 * @return {?proto.proto.AnswerGrpClsFilter}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.AnswerGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFilter, 4));
};


/**
 * @param {?proto.proto.AnswerGrpClsFilter|undefined} value
 * @return {!proto.proto.AnswerGrpClsFilterItem} returns this
*/
proto.proto.AnswerGrpClsFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.AnswerGrpClsFilterItem} returns this
 */
proto.proto.AnswerGrpClsFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.AnswerGrpClsFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.AnswerGrpClsFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsFilter}
 */
proto.proto.AnswerGrpClsFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsFilter;
  return proto.proto.AnswerGrpClsFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsFilter}
 */
proto.proto.AnswerGrpClsFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFilterItem;
      reader.readMessage(value,proto.proto.AnswerGrpClsFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsFilterItem items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsFilterItem>}
 */
proto.proto.AnswerGrpClsFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsFilterItem>} value
 * @return {!proto.proto.AnswerGrpClsFilter} returns this
*/
proto.proto.AnswerGrpClsFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsFilterItem}
 */
proto.proto.AnswerGrpClsFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.AnswerGrpClsFilter} returns this
 */
proto.proto.AnswerGrpClsFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsSort}
 */
proto.proto.AnswerGrpClsSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsSort;
  return proto.proto.AnswerGrpClsSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsSort}
 */
proto.proto.AnswerGrpClsSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.AnswerGrpClsSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsSort} returns this
 */
proto.proto.AnswerGrpClsSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.AnswerGrpClsSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.AnswerGrpClsSort} returns this
 */
proto.proto.AnswerGrpClsSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.AnswerGrpClsPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.AnswerGrpClsPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.AnswerGrpClsPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.AnswerGrpClsPagination}
 */
proto.proto.AnswerGrpClsPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.AnswerGrpClsPagination;
  return proto.proto.AnswerGrpClsPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.AnswerGrpClsPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.AnswerGrpClsPagination}
 */
proto.proto.AnswerGrpClsPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.AnswerGrpClsPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.AnswerGrpClsPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.AnswerGrpClsPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.AnswerGrpClsPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.AnswerGrpClsPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPagination} returns this
 */
proto.proto.AnswerGrpClsPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.AnswerGrpClsPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPagination} returns this
 */
proto.proto.AnswerGrpClsPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.AnswerGrpClsPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPagination} returns this
 */
proto.proto.AnswerGrpClsPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.AnswerGrpClsPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.AnswerGrpClsPagination} returns this
 */
proto.proto.AnswerGrpClsPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsInRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsInRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsInRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerGrpClsPagination.toObject(includeInstance, f),
    questionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsInRequest}
 */
proto.proto.ListAnswerGrpClsInRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsInRequest;
  return proto.proto.ListAnswerGrpClsInRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsInRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsInRequest}
 */
proto.proto.ListAnswerGrpClsInRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpClsSort;
      reader.readMessage(value,proto.proto.AnswerGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerGrpClsPagination;
      reader.readMessage(value,proto.proto.AnswerGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsInRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsInRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsInRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional AnswerGrpClsFilter filter = 1;
 * @return {?proto.proto.AnswerGrpClsFilter}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpClsFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
*/
proto.proto.ListAnswerGrpClsInRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpClsSort sort = 2;
 * @return {?proto.proto.AnswerGrpClsSort}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpClsSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
*/
proto.proto.ListAnswerGrpClsInRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerGrpClsPagination pagination = 3;
 * @return {?proto.proto.AnswerGrpClsPagination}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.AnswerGrpClsPagination|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
*/
proto.proto.ListAnswerGrpClsInRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 question_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 class_id = 5;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsInRequest} returns this
 */
proto.proto.ListAnswerGrpClsInRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpClsInResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsInResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsInResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsInResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsIn.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpClsInRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsInResponse}
 */
proto.proto.ListAnswerGrpClsInResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsInResponse;
  return proto.proto.ListAnswerGrpClsInResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsInResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsInResponse}
 */
proto.proto.ListAnswerGrpClsInResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsIn;
      reader.readMessage(value,proto.proto.AnswerGrpClsIn.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpClsInRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpClsInRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsInResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsInResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsInResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsIn.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpClsInRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsIn items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsIn>}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsIn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsIn, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsIn>} value
 * @return {!proto.proto.ListAnswerGrpClsInResponse} returns this
*/
proto.proto.ListAnswerGrpClsInResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsIn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsIn}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsIn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpClsInResponse} returns this
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpClsInRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpClsInRequest}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpClsInRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpClsInRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpClsInRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsInResponse} returns this
*/
proto.proto.ListAnswerGrpClsInResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsInResponse} returns this
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsInResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsOutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsOutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsOutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.AnswerGrpClsFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.AnswerGrpClsSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.AnswerGrpClsPagination.toObject(includeInstance, f),
    questionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    classId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    notClassifierOnly: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsOutRequest}
 */
proto.proto.ListAnswerGrpClsOutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsOutRequest;
  return proto.proto.ListAnswerGrpClsOutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsOutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsOutRequest}
 */
proto.proto.ListAnswerGrpClsOutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsFilter;
      reader.readMessage(value,proto.proto.AnswerGrpClsFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.AnswerGrpClsSort;
      reader.readMessage(value,proto.proto.AnswerGrpClsSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.AnswerGrpClsPagination;
      reader.readMessage(value,proto.proto.AnswerGrpClsPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setQuestionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setClassId(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNotClassifierOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsOutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsOutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsOutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.AnswerGrpClsFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.AnswerGrpClsSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.AnswerGrpClsPagination.serializeBinaryToWriter
    );
  }
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getClassId();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNotClassifierOnly();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional AnswerGrpClsFilter filter = 1;
 * @return {?proto.proto.AnswerGrpClsFilter}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.AnswerGrpClsFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsFilter, 1));
};


/**
 * @param {?proto.proto.AnswerGrpClsFilter|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
*/
proto.proto.ListAnswerGrpClsOutRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AnswerGrpClsSort sort = 2;
 * @return {?proto.proto.AnswerGrpClsSort}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.AnswerGrpClsSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsSort, 2));
};


/**
 * @param {?proto.proto.AnswerGrpClsSort|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
*/
proto.proto.ListAnswerGrpClsOutRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional AnswerGrpClsPagination pagination = 3;
 * @return {?proto.proto.AnswerGrpClsPagination}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.AnswerGrpClsPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.AnswerGrpClsPagination, 3));
};


/**
 * @param {?proto.proto.AnswerGrpClsPagination|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
*/
proto.proto.ListAnswerGrpClsOutRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 question_id = 4;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 class_id = 5;
 * @return {number}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getClassId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.setClassId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool not_classifier_only = 6;
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.getNotClassifierOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.ListAnswerGrpClsOutRequest} returns this
 */
proto.proto.ListAnswerGrpClsOutRequest.prototype.setNotClassifierOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.ListAnswerGrpClsOutResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.ListAnswerGrpClsOutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.ListAnswerGrpClsOutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsOutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.AnswerGrpClsOut.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.ListAnswerGrpClsOutRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.ListAnswerGrpClsOutResponse}
 */
proto.proto.ListAnswerGrpClsOutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.ListAnswerGrpClsOutResponse;
  return proto.proto.ListAnswerGrpClsOutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.ListAnswerGrpClsOutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.ListAnswerGrpClsOutResponse}
 */
proto.proto.ListAnswerGrpClsOutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.AnswerGrpClsOut;
      reader.readMessage(value,proto.proto.AnswerGrpClsOut.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.ListAnswerGrpClsOutRequest;
      reader.readMessage(value,proto.proto.ListAnswerGrpClsOutRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.ListAnswerGrpClsOutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.ListAnswerGrpClsOutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.ListAnswerGrpClsOutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.AnswerGrpClsOut.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.ListAnswerGrpClsOutRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AnswerGrpClsOut items = 1;
 * @return {!Array<!proto.proto.AnswerGrpClsOut>}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.AnswerGrpClsOut>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.AnswerGrpClsOut, 1));
};


/**
 * @param {!Array<!proto.proto.AnswerGrpClsOut>} value
 * @return {!proto.proto.ListAnswerGrpClsOutResponse} returns this
*/
proto.proto.ListAnswerGrpClsOutResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.AnswerGrpClsOut=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.AnswerGrpClsOut}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.AnswerGrpClsOut, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.ListAnswerGrpClsOutResponse} returns this
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional ListAnswerGrpClsOutRequest params = 2;
 * @return {?proto.proto.ListAnswerGrpClsOutRequest}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.ListAnswerGrpClsOutRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.ListAnswerGrpClsOutRequest, 2));
};


/**
 * @param {?proto.proto.ListAnswerGrpClsOutRequest|undefined} value
 * @return {!proto.proto.ListAnswerGrpClsOutResponse} returns this
*/
proto.proto.ListAnswerGrpClsOutResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.ListAnswerGrpClsOutResponse} returns this
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.ListAnswerGrpClsOutResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
