/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: message_class.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as message_class_pb from './message_class_pb'; // proto import: "message_class.proto"


export class MessageClassServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/CreateMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.CreateMessageClassRequest,
    message_class_pb.CreateMessageClassResponse,
    (request: message_class_pb.CreateMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.CreateMessageClassResponse.deserializeBinary
  );

  createMessageClass(
    request: message_class_pb.CreateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.CreateMessageClassResponse>;

  createMessageClass(
    request: message_class_pb.CreateMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.CreateMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.CreateMessageClassResponse>;

  createMessageClass(
    request: message_class_pb.CreateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.CreateMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/CreateMessageClass',
        request,
        metadata || {},
        this.methodDescriptorCreateMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/CreateMessageClass',
    request,
    metadata || {},
    this.methodDescriptorCreateMessageClass);
  }

  methodDescriptorUpdateMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/UpdateMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.UpdateMessageClassRequest,
    message_class_pb.UpdateMessageClassResponse,
    (request: message_class_pb.UpdateMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.UpdateMessageClassResponse.deserializeBinary
  );

  updateMessageClass(
    request: message_class_pb.UpdateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.UpdateMessageClassResponse>;

  updateMessageClass(
    request: message_class_pb.UpdateMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.UpdateMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.UpdateMessageClassResponse>;

  updateMessageClass(
    request: message_class_pb.UpdateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.UpdateMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/UpdateMessageClass',
        request,
        metadata || {},
        this.methodDescriptorUpdateMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/UpdateMessageClass',
    request,
    metadata || {},
    this.methodDescriptorUpdateMessageClass);
  }

  methodDescriptorDeleteMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/DeleteMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.DeleteMessageClassRequest,
    message_class_pb.DeleteMessageClassResponse,
    (request: message_class_pb.DeleteMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.DeleteMessageClassResponse.deserializeBinary
  );

  deleteMessageClass(
    request: message_class_pb.DeleteMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.DeleteMessageClassResponse>;

  deleteMessageClass(
    request: message_class_pb.DeleteMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.DeleteMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.DeleteMessageClassResponse>;

  deleteMessageClass(
    request: message_class_pb.DeleteMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.DeleteMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/DeleteMessageClass',
        request,
        metadata || {},
        this.methodDescriptorDeleteMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/DeleteMessageClass',
    request,
    metadata || {},
    this.methodDescriptorDeleteMessageClass);
  }

  methodDescriptorListMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/ListMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.ListMessageClassRequest,
    message_class_pb.ListMessageClassResponse,
    (request: message_class_pb.ListMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.ListMessageClassResponse.deserializeBinary
  );

  listMessageClass(
    request: message_class_pb.ListMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.ListMessageClassResponse>;

  listMessageClass(
    request: message_class_pb.ListMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.ListMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.ListMessageClassResponse>;

  listMessageClass(
    request: message_class_pb.ListMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.ListMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/ListMessageClass',
        request,
        metadata || {},
        this.methodDescriptorListMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/ListMessageClass',
    request,
    metadata || {},
    this.methodDescriptorListMessageClass);
  }

  methodDescriptorSaveMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/SaveMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.SaveMessageClassRequest,
    message_class_pb.SaveMessageClassResponse,
    (request: message_class_pb.SaveMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.SaveMessageClassResponse.deserializeBinary
  );

  saveMessageClass(
    request: message_class_pb.SaveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.SaveMessageClassResponse>;

  saveMessageClass(
    request: message_class_pb.SaveMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.SaveMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.SaveMessageClassResponse>;

  saveMessageClass(
    request: message_class_pb.SaveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.SaveMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/SaveMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSaveMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/SaveMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSaveMessageClass);
  }

  methodDescriptorRemoveMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.MessageClassService/RemoveMessageClass',
    grpcWeb.MethodType.UNARY,
    message_class_pb.RemoveMessageClassRequest,
    message_class_pb.RemoveMessageClassResponse,
    (request: message_class_pb.RemoveMessageClassRequest) => {
      return request.serializeBinary();
    },
    message_class_pb.RemoveMessageClassResponse.deserializeBinary
  );

  removeMessageClass(
    request: message_class_pb.RemoveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<message_class_pb.RemoveMessageClassResponse>;

  removeMessageClass(
    request: message_class_pb.RemoveMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: message_class_pb.RemoveMessageClassResponse) => void): grpcWeb.ClientReadableStream<message_class_pb.RemoveMessageClassResponse>;

  removeMessageClass(
    request: message_class_pb.RemoveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: message_class_pb.RemoveMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.MessageClassService/RemoveMessageClass',
        request,
        metadata || {},
        this.methodDescriptorRemoveMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.MessageClassService/RemoveMessageClass',
    request,
    metadata || {},
    this.methodDescriptorRemoveMessageClass);
  }

}

