/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: social_network_message_class.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as social_network_message_class_pb from './social_network_message_class_pb'; // proto import: "social_network_message_class.proto"


export class SocialNetworkMessageClassServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorSocialNetworkCreateMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkCreateMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkCreateMessageClassRequest,
    social_network_message_class_pb.SocialNetworkCreateMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkCreateMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkCreateMessageClassResponse.deserializeBinary
  );

  socialNetworkCreateMessageClass(
    request: social_network_message_class_pb.SocialNetworkCreateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkCreateMessageClassResponse>;

  socialNetworkCreateMessageClass(
    request: social_network_message_class_pb.SocialNetworkCreateMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkCreateMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkCreateMessageClassResponse>;

  socialNetworkCreateMessageClass(
    request: social_network_message_class_pb.SocialNetworkCreateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkCreateMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkCreateMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkCreateMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkCreateMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkCreateMessageClass);
  }

  methodDescriptorSocialNetworkUpdateMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkUpdateMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkUpdateMessageClassRequest,
    social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkUpdateMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse.deserializeBinary
  );

  socialNetworkUpdateMessageClass(
    request: social_network_message_class_pb.SocialNetworkUpdateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse>;

  socialNetworkUpdateMessageClass(
    request: social_network_message_class_pb.SocialNetworkUpdateMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse>;

  socialNetworkUpdateMessageClass(
    request: social_network_message_class_pb.SocialNetworkUpdateMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkUpdateMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkUpdateMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkUpdateMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkUpdateMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkUpdateMessageClass);
  }

  methodDescriptorSocialNetworkDeleteMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkDeleteMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkDeleteMessageClassRequest,
    social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkDeleteMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse.deserializeBinary
  );

  socialNetworkDeleteMessageClass(
    request: social_network_message_class_pb.SocialNetworkDeleteMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse>;

  socialNetworkDeleteMessageClass(
    request: social_network_message_class_pb.SocialNetworkDeleteMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse>;

  socialNetworkDeleteMessageClass(
    request: social_network_message_class_pb.SocialNetworkDeleteMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkDeleteMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkDeleteMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkDeleteMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkDeleteMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkDeleteMessageClass);
  }

  methodDescriptorSocialNetworkListMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkListMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkListMessageClassRequest,
    social_network_message_class_pb.SocialNetworkListMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkListMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkListMessageClassResponse.deserializeBinary
  );

  socialNetworkListMessageClass(
    request: social_network_message_class_pb.SocialNetworkListMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkListMessageClassResponse>;

  socialNetworkListMessageClass(
    request: social_network_message_class_pb.SocialNetworkListMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkListMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkListMessageClassResponse>;

  socialNetworkListMessageClass(
    request: social_network_message_class_pb.SocialNetworkListMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkListMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkListMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkListMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkListMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkListMessageClass);
  }

  methodDescriptorSocialNetworkSaveMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkSaveMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkSaveMessageClassRequest,
    social_network_message_class_pb.SocialNetworkSaveMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkSaveMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkSaveMessageClassResponse.deserializeBinary
  );

  socialNetworkSaveMessageClass(
    request: social_network_message_class_pb.SocialNetworkSaveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkSaveMessageClassResponse>;

  socialNetworkSaveMessageClass(
    request: social_network_message_class_pb.SocialNetworkSaveMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkSaveMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkSaveMessageClassResponse>;

  socialNetworkSaveMessageClass(
    request: social_network_message_class_pb.SocialNetworkSaveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkSaveMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkSaveMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkSaveMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkSaveMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkSaveMessageClass);
  }

  methodDescriptorSocialNetworkRemoveMessageClass = new grpcWeb.MethodDescriptor(
    '/proto.SocialNetworkMessageClassService/SocialNetworkRemoveMessageClass',
    grpcWeb.MethodType.UNARY,
    social_network_message_class_pb.SocialNetworkRemoveMessageClassRequest,
    social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse,
    (request: social_network_message_class_pb.SocialNetworkRemoveMessageClassRequest) => {
      return request.serializeBinary();
    },
    social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse.deserializeBinary
  );

  socialNetworkRemoveMessageClass(
    request: social_network_message_class_pb.SocialNetworkRemoveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse>;

  socialNetworkRemoveMessageClass(
    request: social_network_message_class_pb.SocialNetworkRemoveMessageClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse) => void): grpcWeb.ClientReadableStream<social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse>;

  socialNetworkRemoveMessageClass(
    request: social_network_message_class_pb.SocialNetworkRemoveMessageClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: social_network_message_class_pb.SocialNetworkRemoveMessageClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.SocialNetworkMessageClassService/SocialNetworkRemoveMessageClass',
        request,
        metadata || {},
        this.methodDescriptorSocialNetworkRemoveMessageClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.SocialNetworkMessageClassService/SocialNetworkRemoveMessageClass',
    request,
    metadata || {},
    this.methodDescriptorSocialNetworkRemoveMessageClass);
  }

}

