/**
 * @fileoverview gRPC-Web generated client stub for proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.2
// source: answer_src.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as answer_src_pb from './answer_src_pb'; // proto import: "answer_src.proto"


export class AnswerSrcServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorListAnswerSrc = new grpcWeb.MethodDescriptor(
    '/proto.AnswerSrcService/ListAnswerSrc',
    grpcWeb.MethodType.UNARY,
    answer_src_pb.ListAnswerSrcRequest,
    answer_src_pb.ListAnswerSrcResponse,
    (request: answer_src_pb.ListAnswerSrcRequest) => {
      return request.serializeBinary();
    },
    answer_src_pb.ListAnswerSrcResponse.deserializeBinary
  );

  listAnswerSrc(
    request: answer_src_pb.ListAnswerSrcRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_src_pb.ListAnswerSrcResponse>;

  listAnswerSrc(
    request: answer_src_pb.ListAnswerSrcRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcResponse) => void): grpcWeb.ClientReadableStream<answer_src_pb.ListAnswerSrcResponse>;

  listAnswerSrc(
    request: answer_src_pb.ListAnswerSrcRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerSrcService/ListAnswerSrc',
        request,
        metadata || {},
        this.methodDescriptorListAnswerSrc,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerSrcService/ListAnswerSrc',
    request,
    metadata || {},
    this.methodDescriptorListAnswerSrc);
  }

  methodDescriptorListAnswerSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.AnswerSrcService/ListAnswerSrcSource',
    grpcWeb.MethodType.UNARY,
    answer_src_pb.ListAnswerSrcSourceRequest,
    answer_src_pb.ListAnswerSrcSourceResponse,
    (request: answer_src_pb.ListAnswerSrcSourceRequest) => {
      return request.serializeBinary();
    },
    answer_src_pb.ListAnswerSrcSourceResponse.deserializeBinary
  );

  listAnswerSrcSource(
    request: answer_src_pb.ListAnswerSrcSourceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_src_pb.ListAnswerSrcSourceResponse>;

  listAnswerSrcSource(
    request: answer_src_pb.ListAnswerSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcSourceResponse) => void): grpcWeb.ClientReadableStream<answer_src_pb.ListAnswerSrcSourceResponse>;

  listAnswerSrcSource(
    request: answer_src_pb.ListAnswerSrcSourceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerSrcService/ListAnswerSrcSource',
        request,
        metadata || {},
        this.methodDescriptorListAnswerSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerSrcService/ListAnswerSrcSource',
    request,
    metadata || {},
    this.methodDescriptorListAnswerSrcSource);
  }

  methodDescriptorListAnswerSrcInClass = new grpcWeb.MethodDescriptor(
    '/proto.AnswerSrcService/ListAnswerSrcInClass',
    grpcWeb.MethodType.UNARY,
    answer_src_pb.ListAnswerSrcInClassRequest,
    answer_src_pb.ListAnswerSrcInClassResponse,
    (request: answer_src_pb.ListAnswerSrcInClassRequest) => {
      return request.serializeBinary();
    },
    answer_src_pb.ListAnswerSrcInClassResponse.deserializeBinary
  );

  listAnswerSrcInClass(
    request: answer_src_pb.ListAnswerSrcInClassRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_src_pb.ListAnswerSrcInClassResponse>;

  listAnswerSrcInClass(
    request: answer_src_pb.ListAnswerSrcInClassRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcInClassResponse) => void): grpcWeb.ClientReadableStream<answer_src_pb.ListAnswerSrcInClassResponse>;

  listAnswerSrcInClass(
    request: answer_src_pb.ListAnswerSrcInClassRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_src_pb.ListAnswerSrcInClassResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerSrcService/ListAnswerSrcInClass',
        request,
        metadata || {},
        this.methodDescriptorListAnswerSrcInClass,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerSrcService/ListAnswerSrcInClass',
    request,
    metadata || {},
    this.methodDescriptorListAnswerSrcInClass);
  }

  methodDescriptorPersonalListAnswerSrcSource = new grpcWeb.MethodDescriptor(
    '/proto.AnswerSrcService/PersonalListAnswerSrcSource',
    grpcWeb.MethodType.UNARY,
    answer_src_pb.PersonalListAnswerSrcSourceRequest,
    answer_src_pb.PersonalListAnswerSrcSourceResponse,
    (request: answer_src_pb.PersonalListAnswerSrcSourceRequest) => {
      return request.serializeBinary();
    },
    answer_src_pb.PersonalListAnswerSrcSourceResponse.deserializeBinary
  );

  personalListAnswerSrcSource(
    request: answer_src_pb.PersonalListAnswerSrcSourceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<answer_src_pb.PersonalListAnswerSrcSourceResponse>;

  personalListAnswerSrcSource(
    request: answer_src_pb.PersonalListAnswerSrcSourceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: answer_src_pb.PersonalListAnswerSrcSourceResponse) => void): grpcWeb.ClientReadableStream<answer_src_pb.PersonalListAnswerSrcSourceResponse>;

  personalListAnswerSrcSource(
    request: answer_src_pb.PersonalListAnswerSrcSourceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: answer_src_pb.PersonalListAnswerSrcSourceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/proto.AnswerSrcService/PersonalListAnswerSrcSource',
        request,
        metadata || {},
        this.methodDescriptorPersonalListAnswerSrcSource,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/proto.AnswerSrcService/PersonalListAnswerSrcSource',
    request,
    metadata || {},
    this.methodDescriptorPersonalListAnswerSrcSource);
  }

}

