// source: social_network_message_grp.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.proto.SocialNetworkCreateMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkCreateMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkDeleteMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkDeleteMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpChildsRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpChildsResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrp', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpFilter', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpFilterItem', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpPagination', null, global);
goog.exportSymbol('proto.proto.SocialNetworkMessageGrpSort', null, global);
goog.exportSymbol('proto.proto.SocialNetworkReduceMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkReduceMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkSeparateMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkSeparateMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkSplitMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkSplitMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkUpdateMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkUpdateMessageGrpResponse', null, global);
goog.exportSymbol('proto.proto.SocialNetworkUpdateTextMessageGrpRequest', null, global);
goog.exportSymbol('proto.proto.SocialNetworkUpdateTextMessageGrpResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrp.displayName = 'proto.proto.SocialNetworkMessageGrp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpFilterItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpFilterItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpFilterItem.displayName = 'proto.proto.SocialNetworkMessageGrpFilterItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkMessageGrpFilter.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpFilter.displayName = 'proto.proto.SocialNetworkMessageGrpFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpSort.displayName = 'proto.proto.SocialNetworkMessageGrpSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkMessageGrpPagination = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkMessageGrpPagination, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkMessageGrpPagination.displayName = 'proto.proto.SocialNetworkMessageGrpPagination';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpRequest.displayName = 'proto.proto.SocialNetworkListMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageGrpResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpResponse.displayName = 'proto.proto.SocialNetworkListMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.displayName = 'proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.displayName = 'proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpChildsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpChildsRequest.displayName = 'proto.proto.SocialNetworkListMessageGrpChildsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkListMessageGrpChildsResponse.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkListMessageGrpChildsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkListMessageGrpChildsResponse.displayName = 'proto.proto.SocialNetworkListMessageGrpChildsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkCreateMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkCreateMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkCreateMessageGrpRequest.displayName = 'proto.proto.SocialNetworkCreateMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkCreateMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkCreateMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkCreateMessageGrpResponse.displayName = 'proto.proto.SocialNetworkCreateMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkUpdateMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkUpdateMessageGrpRequest.displayName = 'proto.proto.SocialNetworkUpdateMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkUpdateMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkUpdateMessageGrpResponse.displayName = 'proto.proto.SocialNetworkUpdateMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkUpdateTextMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkUpdateTextMessageGrpRequest.displayName = 'proto.proto.SocialNetworkUpdateTextMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkUpdateTextMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkUpdateTextMessageGrpResponse.displayName = 'proto.proto.SocialNetworkUpdateTextMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkDeleteMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkDeleteMessageGrpRequest.displayName = 'proto.proto.SocialNetworkDeleteMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkDeleteMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkDeleteMessageGrpResponse.displayName = 'proto.proto.SocialNetworkDeleteMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkReduceMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkReduceMessageGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkReduceMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkReduceMessageGrpRequest.displayName = 'proto.proto.SocialNetworkReduceMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkReduceMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkReduceMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkReduceMessageGrpResponse.displayName = 'proto.proto.SocialNetworkReduceMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkSplitMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkSplitMessageGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkSplitMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkSplitMessageGrpRequest.displayName = 'proto.proto.SocialNetworkSplitMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkSplitMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkSplitMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkSplitMessageGrpResponse.displayName = 'proto.proto.SocialNetworkSplitMessageGrpResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.proto.SocialNetworkSeparateMessageGrpRequest.repeatedFields_, null);
};
goog.inherits(proto.proto.SocialNetworkSeparateMessageGrpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkSeparateMessageGrpRequest.displayName = 'proto.proto.SocialNetworkSeparateMessageGrpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.proto.SocialNetworkSeparateMessageGrpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.proto.SocialNetworkSeparateMessageGrpResponse.displayName = 'proto.proto.SocialNetworkSeparateMessageGrpResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrp.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrp.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpParentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    messageGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    messageGrpSum: jspb.Message.getFieldWithDefault(msg, 6, 0),
    similarity: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    hasChilds: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrp}
 */
proto.proto.SocialNetworkMessageGrp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrp;
  return proto.proto.SocialNetworkMessageGrp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrp}
 */
proto.proto.SocialNetworkMessageGrp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpParentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMessageGrpProcessed(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpSum(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSimilarity(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasChilds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMessageGrpProcessed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getMessageGrpSum();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getSimilarity();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getHasChilds();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 message_grp_parent_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string message_grp_text = 3;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 message_grp_count = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool message_grp_processed = 5;
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional int64 message_grp_sum = 6;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getMessageGrpSum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setMessageGrpSum = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional double similarity = 7;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getSimilarity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setSimilarity = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional bool has_childs = 8;
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageGrp.prototype.getHasChilds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkMessageGrp} returns this
 */
proto.proto.SocialNetworkMessageGrp.prototype.setHasChilds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpFilterItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpFilterItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpFilterItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    fieldName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    operand: jspb.Message.getFieldWithDefault(msg, 2, ""),
    value: jspb.Message.getFieldWithDefault(msg, 3, ""),
    or: (f = msg.getOr()) && proto.proto.SocialNetworkMessageGrpFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpFilterItem;
  return proto.proto.SocialNetworkMessageGrpFilterItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpFilterItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 4:
      var value = new proto.proto.SocialNetworkMessageGrpFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader);
      msg.setOr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpFilterItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpFilterItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpFilterItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFieldName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOperand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOr();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional string field_name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.getFieldName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.setFieldName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string operand = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.getOperand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.setOperand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SocialNetworkMessageGrpFilter or = 4;
 * @return {?proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.getOr = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpFilter, 4));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpFilter|undefined} value
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem} returns this
*/
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.setOr = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem} returns this
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.clearOr = function() {
  return this.setOr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkMessageGrpFilterItem.prototype.hasOr = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkMessageGrpFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrpFilterItem.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkMessageGrpFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpFilter;
  return proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpFilterItem;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpFilterItem.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpFilterItem.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrpFilterItem items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrpFilterItem>}
 */
proto.proto.SocialNetworkMessageGrpFilter.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrpFilterItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrpFilterItem, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrpFilterItem>} value
 * @return {!proto.proto.SocialNetworkMessageGrpFilter} returns this
*/
proto.proto.SocialNetworkMessageGrpFilter.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrpFilterItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrpFilterItem}
 */
proto.proto.SocialNetworkMessageGrpFilter.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrpFilterItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkMessageGrpFilter} returns this
 */
proto.proto.SocialNetworkMessageGrpFilter.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    exp: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpSort}
 */
proto.proto.SocialNetworkMessageGrpSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpSort;
  return proto.proto.SocialNetworkMessageGrpSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpSort}
 */
proto.proto.SocialNetworkMessageGrpSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExp();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpSort} returns this
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string exp = 2;
 * @return {string}
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.getExp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkMessageGrpSort} returns this
 */
proto.proto.SocialNetworkMessageGrpSort.prototype.setExp = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkMessageGrpPagination.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkMessageGrpPagination} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpPagination.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    pages: jspb.Message.getFieldWithDefault(msg, 3, 0),
    cnt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkMessageGrpPagination}
 */
proto.proto.SocialNetworkMessageGrpPagination.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkMessageGrpPagination;
  return proto.proto.SocialNetworkMessageGrpPagination.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkMessageGrpPagination} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkMessageGrpPagination}
 */
proto.proto.SocialNetworkMessageGrpPagination.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPages(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCnt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkMessageGrpPagination.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkMessageGrpPagination} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkMessageGrpPagination.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getPages();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getCnt();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 page = 1;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 limit = 2;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 pages = 3;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.getPages = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.setPages = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 cnt = 4;
 * @return {number}
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.getCnt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkMessageGrpPagination} returns this
 */
proto.proto.SocialNetworkMessageGrpPagination.prototype.setCnt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageGrpPagination.toObject(includeInstance, f),
    isWorked: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest}
 */
proto.proto.SocialNetworkListMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpRequest;
  return proto.proto.SocialNetworkListMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest}
 */
proto.proto.SocialNetworkListMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageGrpSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageGrpPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsWorked(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getIsWorked();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageGrpFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageGrpSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageGrpSort}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageGrpPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageGrpPagination}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_worked = 4;
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.getIsWorked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkListMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpRequest.prototype.setIsWorked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageGrpResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageGrpRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse}
 */
proto.proto.SocialNetworkListMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpResponse;
  return proto.proto.SocialNetworkListMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse}
 */
proto.proto.SocialNetworkListMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrp;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageGrpRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageGrpRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageGrpRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrp items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrp>}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrp, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrp>} value
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrp}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageGrpRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageGrpRequest}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageGrpRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageGrpRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageGrpRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageGrpPagination.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest;
  return proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageGrpSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageGrpPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageGrpFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageGrpSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageGrpSort}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageGrpPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageGrpPagination}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 message_grp_id = 4;
 * @return {number}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse;
  return proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrp;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrp items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrp>}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrp, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrp>} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrp}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageGrpWithFindMessageRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageGrpWithFindMessageRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpWithFindMessageResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpChildsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: (f = msg.getFilter()) && proto.proto.SocialNetworkMessageGrpFilter.toObject(includeInstance, f),
    sort: (f = msg.getSort()) && proto.proto.SocialNetworkMessageGrpSort.toObject(includeInstance, f),
    pagination: (f = msg.getPagination()) && proto.proto.SocialNetworkMessageGrpPagination.toObject(includeInstance, f),
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpChildsRequest;
  return proto.proto.SocialNetworkListMessageGrpChildsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrpFilter;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpFilter.deserializeBinaryFromReader);
      msg.setFilter(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkMessageGrpSort;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpSort.deserializeBinaryFromReader);
      msg.setSort(value);
      break;
    case 3:
      var value = new proto.proto.SocialNetworkMessageGrpPagination;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrpPagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpChildsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrpFilter.serializeBinaryToWriter
    );
  }
  f = message.getSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkMessageGrpSort.serializeBinaryToWriter
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.proto.SocialNetworkMessageGrpPagination.serializeBinaryToWriter
    );
  }
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional SocialNetworkMessageGrpFilter filter = 1;
 * @return {?proto.proto.SocialNetworkMessageGrpFilter}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.getFilter = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpFilter} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpFilter, 1));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpFilter|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.clearFilter = function() {
  return this.setFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.hasFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SocialNetworkMessageGrpSort sort = 2;
 * @return {?proto.proto.SocialNetworkMessageGrpSort}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.getSort = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpSort} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpSort, 2));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpSort|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.setSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.clearSort = function() {
  return this.setSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.hasSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SocialNetworkMessageGrpPagination pagination = 3;
 * @return {?proto.proto.SocialNetworkMessageGrpPagination}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.getPagination = function() {
  return /** @type{?proto.proto.SocialNetworkMessageGrpPagination} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkMessageGrpPagination, 3));
};


/**
 * @param {?proto.proto.SocialNetworkMessageGrpPagination|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
*/
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 message_grp_id = 4;
 * @return {number}
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsRequest} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkListMessageGrpChildsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.proto.SocialNetworkMessageGrp.toObject, includeInstance),
    params: (f = msg.getParams()) && proto.proto.SocialNetworkListMessageGrpChildsRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkListMessageGrpChildsResponse;
  return proto.proto.SocialNetworkListMessageGrpChildsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.proto.SocialNetworkMessageGrp;
      reader.readMessage(value,proto.proto.SocialNetworkMessageGrp.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = new proto.proto.SocialNetworkListMessageGrpChildsRequest;
      reader.readMessage(value,proto.proto.SocialNetworkListMessageGrpChildsRequest.deserializeBinaryFromReader);
      msg.setParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkListMessageGrpChildsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkListMessageGrpChildsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.proto.SocialNetworkMessageGrp.serializeBinaryToWriter
    );
  }
  f = message.getParams();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.proto.SocialNetworkListMessageGrpChildsRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SocialNetworkMessageGrp items = 1;
 * @return {!Array<!proto.proto.SocialNetworkMessageGrp>}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.proto.SocialNetworkMessageGrp>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.proto.SocialNetworkMessageGrp, 1));
};


/**
 * @param {!Array<!proto.proto.SocialNetworkMessageGrp>} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.proto.SocialNetworkMessageGrp=} opt_value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkMessageGrp}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.proto.SocialNetworkMessageGrp, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional SocialNetworkListMessageGrpChildsRequest params = 2;
 * @return {?proto.proto.SocialNetworkListMessageGrpChildsRequest}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.getParams = function() {
  return /** @type{?proto.proto.SocialNetworkListMessageGrpChildsRequest} */ (
    jspb.Message.getWrapperField(this, proto.proto.SocialNetworkListMessageGrpChildsRequest, 2));
};


/**
 * @param {?proto.proto.SocialNetworkListMessageGrpChildsRequest|undefined} value
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse} returns this
*/
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.setParams = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.proto.SocialNetworkListMessageGrpChildsResponse} returns this
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.clearParams = function() {
  return this.setParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.proto.SocialNetworkListMessageGrpChildsResponse.prototype.hasParams = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkCreateMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkCreateMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpParentId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 3, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 4, 0),
    messageGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkCreateMessageGrpRequest;
  return proto.proto.SocialNetworkCreateMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkCreateMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpParentId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMessageGrpProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkCreateMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkCreateMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getMessageGrpProcessed();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int64 message_grp_parent_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.getMessageGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.setMessageGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 message_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string message_grp_text = 3;
 * @return {string}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 message_grp_count = 4;
 * @return {number}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool message_grp_processed = 5;
 * @return {boolean}
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.getMessageGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpRequest.prototype.setMessageGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkCreateMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkCreateMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkCreateMessageGrpResponse}
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkCreateMessageGrpResponse;
  return proto.proto.SocialNetworkCreateMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkCreateMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkCreateMessageGrpResponse}
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkCreateMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkCreateMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkCreateMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkCreateMessageGrpResponse.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkUpdateMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpParentId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    messageId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 4, ""),
    messageGrpCount: jspb.Message.getFieldWithDefault(msg, 5, 0),
    messageGrpProcessed: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkUpdateMessageGrpRequest;
  return proto.proto.SocialNetworkUpdateMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpParentId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpCount(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMessageGrpProcessed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkUpdateMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpParentId();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getMessageId();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMessageGrpCount();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getMessageGrpProcessed();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 message_grp_parent_id = 2;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageGrpParentId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageGrpParentId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 message_id = 3;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string message_grp_text = 4;
 * @return {string}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 message_grp_count = 5;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageGrpCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageGrpCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool message_grp_processed = 6;
 * @return {boolean}
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.getMessageGrpProcessed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpRequest.prototype.setMessageGrpProcessed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkUpdateMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpResponse}
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkUpdateMessageGrpResponse;
  return proto.proto.SocialNetworkUpdateMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpResponse}
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkUpdateMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkUpdateMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkUpdateMessageGrpResponse.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkUpdateTextMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    messageGrpText: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkUpdateTextMessageGrpRequest;
  return proto.proto.SocialNetworkUpdateTextMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageGrpText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkUpdateTextMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getMessageGrpText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message_grp_text = 2;
 * @return {string}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.getMessageGrpText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkUpdateTextMessageGrpRequest.prototype.setMessageGrpText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkUpdateTextMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkUpdateTextMessageGrpResponse;
  return proto.proto.SocialNetworkUpdateTextMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkUpdateTextMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkUpdateTextMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkUpdateTextMessageGrpResponse.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkDeleteMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpRequest}
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkDeleteMessageGrpRequest;
  return proto.proto.SocialNetworkDeleteMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpRequest}
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkDeleteMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkDeleteMessageGrpRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkDeleteMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpResponse}
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkDeleteMessageGrpResponse;
  return proto.proto.SocialNetworkDeleteMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpResponse}
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkDeleteMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkDeleteMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkDeleteMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkDeleteMessageGrpResponse.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkReduceMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkReduceMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reducedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkReduceMessageGrpRequest;
  return proto.proto.SocialNetworkReduceMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkReduceMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addReducedIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkReduceMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkReduceMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getReducedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int64 reduced_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.getReducedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.setReducedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.addReducedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkReduceMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkReduceMessageGrpRequest.prototype.clearReducedIdsList = function() {
  return this.setReducedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkReduceMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkReduceMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageGrpId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkReduceMessageGrpResponse}
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkReduceMessageGrpResponse;
  return proto.proto.SocialNetworkReduceMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkReduceMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkReduceMessageGrpResponse}
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setMessageGrpId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkReduceMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkReduceMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageGrpId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 message_grp_id = 1;
 * @return {number}
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.prototype.getMessageGrpId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.proto.SocialNetworkReduceMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkReduceMessageGrpResponse.prototype.setMessageGrpId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkSplitMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkSplitMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    splitString: jspb.Message.getFieldWithDefault(msg, 1, ""),
    splittedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkSplitMessageGrpRequest;
  return proto.proto.SocialNetworkSplitMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkSplitMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitString(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSplittedIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkSplitMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkSplitMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitString();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSplittedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * optional string split_string = 1;
 * @return {string}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.getSplitString = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.setSplitString = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated int64 splitted_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.getSplittedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.setSplittedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.addSplittedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkSplitMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSplitMessageGrpRequest.prototype.clearSplittedIdsList = function() {
  return this.setSplittedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkSplitMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkSplitMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkSplitMessageGrpResponse}
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkSplitMessageGrpResponse;
  return proto.proto.SocialNetworkSplitMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkSplitMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkSplitMessageGrpResponse}
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkSplitMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkSplitMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkSplitMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkSplitMessageGrpResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkSeparateMessageGrpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    separatedIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpRequest}
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkSeparateMessageGrpRequest;
  return proto.proto.SocialNetworkSeparateMessageGrpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpRequest}
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSeparatedIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkSeparateMessageGrpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeparatedIdsList();
  if (f.length > 0) {
    writer.writePackedInt64(
      2,
      f
    );
  }
};


/**
 * repeated int64 separated_ids = 2;
 * @return {!Array<number>}
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.getSeparatedIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.setSeparatedIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.addSeparatedIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpRequest} returns this
 */
proto.proto.SocialNetworkSeparateMessageGrpRequest.prototype.clearSeparatedIdsList = function() {
  return this.setSeparatedIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.proto.SocialNetworkSeparateMessageGrpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    success: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpResponse}
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.proto.SocialNetworkSeparateMessageGrpResponse;
  return proto.proto.SocialNetworkSeparateMessageGrpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpResponse}
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSuccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.proto.SocialNetworkSeparateMessageGrpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.proto.SocialNetworkSeparateMessageGrpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSuccess();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool success = 1;
 * @return {boolean}
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.prototype.getSuccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.proto.SocialNetworkSeparateMessageGrpResponse} returns this
 */
proto.proto.SocialNetworkSeparateMessageGrpResponse.prototype.setSuccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.proto);
module.exports = proto.proto;
